import React, { useState, useEffect } from 'react'
import DataTable from './DataTable/DataTable'
import DataTableExcel from './DataTable/DataTableExcel'
import { Accordion, Button, Dropdown, Modal, Nav, ProgressBar } from 'react-bootstrap'
import { api_url } from '../../../utils/Apiurl'
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";
import { toast } from 'react-toastify';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom'
import Influencerdetail from '../Influencerdetail/Influencerdetail'
import Multiselect from 'multiselect-react-dropdown';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom'
import './allinfluencer.scss'
import ReferalDataExcel from './DataTable/ReferalDataExcel'
// import ReferalDataExcel from './DataTable/ReferalDataExcel'


const Allinfluencer = (
  { setindexwait, routes, setRoutes }
) => {
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
    budget: "",
  });
  const [show, setShow] = useState(false);
  const [nameerror, setNameError] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [budgeterror, setBudgetError] = useState("");
  const [influencer, setInfluencer] = useState([]);
  const [influencerExcel, setInfluencerExcel] = useState([]);
  const [referalexcel, setReferalExcel] = useState([]);
  const [influenceradd, setInfluencerAdd] = useState([]);
  const [influencerstats, setInfluencerStats] = useState([]);
  const [socialclicks, setSocialClicks] = useState([]);
  const [latestusers, setLatestUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const token = localStorage.getItem("miangInfu");
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const itemsPerPage = 10;
  const [countries, setCountries] = useState([]);
  const [topcampaignslist, setTopCampaingslist] = useState([]);
  const [groupstoadd, setGroupsToAdd] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ id: '', selectedname: '' });
  // console.log("eeeeeeeeeeee", countries);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchlist, setSearchlist] = useState([]);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [selectedsearchItem, setSelectedSearchItem] = useState(null);
  const [copiedItemId, setCopiedItemId] = useState(null);




  const AddInfluencers = async (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // reset error states
    setNameError("");
    setEmailError("");
    setBudgetError("");
    // validation
    if (inputs.name === "") {
      setNameError("Name is required");
    }
    if (inputs.email.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailPattern.test(inputs.email)) {
      setEmailError("Invalid email format");
    }
    if (inputs.budget === "") {
      setBudgetError("Budget is required");
    }
    try {
      const config = {
        method: "post",
        url: `${api_url}/influencers`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      let data;
      if (selectedItem && selectedItem.id) {
        data = {
          name: inputs.name,
          email: inputs.email,
          budget: parseFloat(inputs.budget),
          groupId: selectedItem.id,
        };
      } else {
        data = {
          name: inputs.name,
          email: inputs.email,
          budget: parseFloat(inputs.budget),
        };
      }
      config.data = data;
      const response = await axios(config);
      console.log(response.data);
      setInputs({
        email: "",
        name: "",
        budget: "",
      });
      setInfluencerAdd(response?.data?.data);
      getAllInfluencers();
      getInfluencerStats();
      setApiSuccess(true);
      toast.success("Influencer added successfully");
    } catch (error) {
      if (error) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred");
      }
      console.error('Error fetching project details:', error);
    }
  };



  useEffect(() => {
    AddInfluencers();
  }, []);
  if (!token) {
    navigate("/")
  }


  const getAllInfluencers = async (searchname) => {
    setLoader(true)
    try {
      const response = await axios.get(`${api_url}/influencers?${searchTerm && `search=${searchTerm}&`}offset=${`${searchTerm ? 0 : page}`}&limit=10`, {
        params: {
          name: searchname || null,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInfluencer(response?.data?.data);
      setPageCount(response?.data?.totalCount);
      setLoader(false)
      console.log(response, "sdsdwed");
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/');
      } else {
        console.error('Error fetching influencers:', err);
        throw err;
      }
      setLoader(false)
    }
  };

  let timeoutId;
  useEffect(() => {
    // Use a local variable to store the timeout ID
    let timeoutId;

    // Clear the previous setTimeout on rapid changes
    clearTimeout(timeoutId);

    // Set a new timeout for the current input
    timeoutId = setTimeout(() => {
      getAllInfluencers();
    }, 1000); // Adjust the delay time as needed

    // Cleanup function to cancel the current setTimeout on component unmount or when the input changes
    return () => clearTimeout(timeoutId);
  }, [page, searchTerm]);

  const getAllInfluencersExcel = async () => {
    try {
      const response = await axios.get(`${api_url}/influencers?offset=${`${page}`}&limit=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInfluencerExcel(response?.data?.data);
      console.log(response?.data?.totalCount, "sdsdwed");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  const getReferalExcel = async () => {
    try {
      const response = await axios.get(`${api_url}/influencers/referrals-data?offset=${`${page}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReferalExcel(response?.data?.data);
      console.log(response?.data?.totalCount, "sdsdwed");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  const getSocialLinks = async () => {
    try {
      const response = await axios.get(`${api_url}/rewards/clicks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSocialClicks(response?.data?.data);
      console.log(response?.data?.totalCount, "sdsdwed");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getSocialLinks();
  }, []);
  const getUserinLastHour = async () => {
    try {
      const response = await axios.get(`${api_url}/influencers/stats/growth`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLatestUsers(response?.data?.data);
      console.log(response?.data?.totalCount, "sdsdwed");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getUserinLastHour();
  }, []);


  useEffect(() => {
    getAllInfluencersExcel();
  }, []);
  useEffect(() => {
    getReferalExcel();
  }, []);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const { email, name, budget } = inputs;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    if (name === "email") {
      setEmailError("")
    } else if (name === "name") {
      setNameError("")
    } else if (name === "budget") {
      setBudgetError("")
    }
  };

  const startIndex = page * itemsPerPage + 1;
  const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);



  const textCopiedFun2 = (itemId) => {
    console.log(itemId, 'itemId');
    setCopiedItemId(itemId);
    setTimeout(() => {
      setCopiedItemId(null);
    }, 500);
  };


  // const getAllCountries = async () => {
  //   try {
  //     const response = await axios.get(`${api_url}/rewards/topCountry?limit=10`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setCountries(response?.data?.data);
  //   } catch (err) {
  //     console.error('Error fetching countries:', err);
  //   }
  // };
  // console.log(countries, "countries");
  // useEffect(() => {
  //   getAllCountries()
  // }, [])

  // const getTopCampaings = async () => {
  //   try {
  //     const response = await axios.get(`${api_url}/groups/topCampaings`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setTopCampaingslist(response?.data?.data?.data);
  //   } catch (err) {
  //     console.error('Error fetching influencers:', err);
  //   }
  // };
  // useEffect(() => {
  //   getTopCampaings()
  // }, [])

  // const getTopInfluncers = async () => {
  //   try {
  //     const response = await axios.get(`${api_url}/influencers/topInfluencers`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setTopInfluencers(response?.data?.data);
  //     console.log(response?.data?.data?.influencersData, "sfrrvtmikimmi");
  //   } catch (err) {
  //     console.error('Error fetching influencers:', err);
  //   }
  // };
  // useEffect(() => {
  //   getTopInfluncers()
  // }, []);



  const getGroupstoAdd = async (id, selectedname) => {
    try {
      const response = await axios.get(`${api_url}/groups/getNames?&limit=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setGroupsToAdd(response?.data?.data);

    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };

  const handleSelect = (id, selectedname) => {
    setSelectedItem({ id, selectedname });
    // setGroupError("")
  };

  useEffect(() => {
    getGroupstoAdd(selectedItem.id, selectedItem.selectedname);
  }, []);


  const getInfluencerStats = async () => {
    try {
      const response = await axios.get(`${api_url}/influencers/AllStats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInfluencerStats(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getInfluencerStats();
  }, []);




  const getSearchInfluencers = async (searchname) => {
    if (!searchname) {
      setIsSearchEmpty(true);
      return;
    }
    try {
      const response = await axios.get(`${api_url}/groups/searchNames`, {
        params: { name: searchname },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSearchlist(response?.data?.data);
      setIsSearchEmpty(false);
      console.log(response, "sseweeer");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  const handleDropdownItemClick = (item) => {
    setSelectedSearchItem(item.name);
    getAllInfluencers(item.name);
  };


  const debouncedSearch = debounce((value) => getSearchInfluencers(value), 1000);

  const handleInputChange = (e) => {
    const { value } = e.target;
    debouncedSearch(value);
  };
  useEffect(() => {
    if (isSearchEmpty) {
      getAllInfluencers();
    }
  }, [isSearchEmpty]);


  const [selectedItems, setSelectedItems] = useState("Countries");
  const items = ["Countries", "Campaigns", "Influencers"];

  const [changetext, setchangetext] = useState(false)
  const [changetext2, setchangetext2] = useState(false)
  const [changetext3, setchangetext3] = useState(false)
  console.log(topcampaignslist, "groupss");


  return (
    <>
      <section className="allinfluencer">
        <div className="custom-container">
          <div className="main-heading">
            <h6>All Influencers</h6>
            <div className="mainheadingbtns">
              <div className="searchinputt">
                <input
                  type="text"
                  name="text"
                  value={searchTerm}
                  onChange={(e) => { setSearchTerm(e.target.value) }}
                  placeholder='Search influencers'
                // onChange={handleInputChange}
                />
                <img src="\search-normal.svg" alt="searchimg" className="searchimg" />
              </div>
              <Dropdown className='searchdropdown'>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedsearchItem ? <>{selectedsearchItem}</> : "Filter by campaign"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="searchinput">
                    <input
                      type="text"
                      placeholder='Search'
                      onChange={handleInputChange}
                    />
                    <img src="\search-normal.svg" alt="searchimg" className="searchimg" />
                  </div>
                  {searchlist?.map((item, index) => (
                    <Dropdown.Item className="searchitem" key={index} onClick={() => handleDropdownItemClick(item)}>
                      {item?.name}
                    </Dropdown.Item >
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn-influencer"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export To Excel"
              />
              {referalexcel?.length < 1
                ?
                <button style={{ opacity: "0.5" }} disabled={referalexcel?.length < 1} data-toggle="tooltip" data-placement="top" title={referalexcel?.length < 1 && "Data is loading ..."} className="btn-influencer">
                  New Export
                </button>
                :
                <ReactHTMLTableToExcel
                  id="test-table-xls-two-button"
                  className="btn-influencer"
                  table="table-to-xls-two"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Referals Export"
                />
              }
              {/* <button className='btn-influencer'>Export To Excel</button> */}
              {/* <button onClick={handleShow} className='btn-influencer'>Add New Influencer</button> */}
            </div>
          </div>
          <DataTable pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} influencer={influencer} setRoutes={setRoutes}
            routes={routes} loader={loader} />
          <DataTableExcel pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} influencer={influencerExcel} setRoutes={setRoutes}
            routes={routes} excelId={'table-to-xls'} />
          <ReferalDataExcel pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} influencer={referalexcel} setRoutes={setRoutes}
            routes={routes} excelId={'table-to-xls-two'} />
        </div>
      </section>
      <Modal
        className="add-mega-modal global-modal-scss"
        show={show}
        onHide={() => { handleClose() }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Influencer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="name"
                  name="name"
                  placeholder="Enter influencer’s Name"
                  value={name}
                  onChange={handleChange}
                  className={
                    "" +
                    (submitted && !name ? " is-invalid" : "")
                  }
                />
                {nameerror ? (
                  <p className="mt-2 text-danger email-error">{nameerror}</p>
                ) : null}
                <label>Name</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter influencer’s Email address"
                  value={email}
                  onChange={handleChange}
                  className={
                    "" +
                    (submitted && !email ? " is-invalid" : "")
                  }
                />
                {emailerror ? (
                  <p className="mt-2 text-danger email-error">{emailerror}</p>
                ) : null}
                <label>Email</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="number"
                  name="budget"
                  value={budget}
                  onChange={handleChange}
                  placeholder="Enter influencer’s budget"
                  className={
                    "" +
                    (submitted && !budget ? " is-invalid" : "")
                  }
                />
                {budgeterror ? (
                  <p className="mt-2 text-danger email-error">{budgeterror}</p>
                ) : null}
                <label>Budget</label>
                <img src="\assets\dollar.svg" alt="dollarimg" className="dollarimg" />
              </div>
            </div>

            {/* <Multiselect
              className="multiselect-root"
              placeholder="Select Campaign"
              // selectedValues={selectedItem?.selectedname ? [selectedItem.selectedname] : []}
              isObject={false}
              onKeyPressFn={() => { }}
              onRemove={handleMultiselectRemove}
              onSearch={() => { }}
              onSelect={handleMultiselectSelect}
              customCloseIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              options={groupstoadd?.map((item) => item?.name)}
            />  */}
            <Dropdown>
              <Dropdown.Toggle>
                {selectedItem.selectedname || 'Select Campaign'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {groupstoadd?.map((data, index) => (
                  <Dropdown.Item
                    onClick={() => handleSelect(data._id, data.name)}
                    key={index}
                    eventKey={data._id}
                  >
                    {data?.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
              {/* {grouperror ? (
                <p className="text-danger email-error">{grouperror}</p>
              ) : null} */}
            </Dropdown>
          </div>
          <div className="checkboxmain">
            <div class="content">
              <label class="checkBox">
                <input type="checkbox" id="ch1" />
                <div class="transition"></div>
              </label>
            </div>
            <p className="checkboxpara">Internal marketing account</p>
          </div>
          <div className="twice-btn">
            <button
              onClick={handleClose}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              onClick={(e) => {
                // handleClose();
                handleShow1();
                AddInfluencers(e);
              }}
              className="btn-add"
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        apiSuccess && (
          <Modal
            className="add-mega-modal global-modal-scss"
            show={show1}
            onHide={handleClose1}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Influencer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="modalhead">New Influencer has been added</h6>
              <p className="modalpara">
                https://doptest.dop.org/id={influenceradd?.data?.referalCode}{' '}
                <CopyToClipboard
                  text={`${"https://doptest.dop.org/id=" + influenceradd?.data?.referalCode}`}
                  onCopy={() => textCopiedFun2(influenceradd)}
                >
                  <img className={copiedItemId === influenceradd && "copy-button-add"} src="/assets/copy.svg" alt="copy" />
                </CopyToClipboard>

              </p>
              <button
                onClick={() => {
                  handleClose1();
                  handleClose();
                }}
                className="btn-login"
              >
                OKAY
              </button>
            </Modal.Body>
          </Modal>
        )
      }
    </>
  )
}

export default Allinfluencer