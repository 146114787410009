import React, { useState, useEffect } from 'react'
import DataTable from './DataTable/DataTable'
import { Dropdown, Modal } from 'react-bootstrap'
import Groupsdetails from './Groupdetails/Groupsdetails';
import { toast } from 'react-toastify';
import { api_url } from '../../../utils/Apiurl'
import axios from 'axios'
import { id } from 'date-fns/locale';
import Multiselect from 'multiselect-react-dropdown';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

const Groups = ({ setindexwait, setRend, routes, setRoutes, rend }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [inputs, setInputs] = useState({
    name: "",
    groups: "",
  })
  const [inputsinf, setInputsInf] = useState({
    emailinf: "",
    nameinf: "",
    budgetinf: "",
  });
  const [grouperror, setGroupError] = useState("");
  const token = localStorage.getItem("miangInfu");
  const [groupsadd, setGroupsAdd] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [names, setNames] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ id: '', selectedname: '' });
  const [groupslist, setGroupsList] = useState([]);
  const itemsPerPage = 10;
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [specialAccess, setAddSpecialAccess] = useState([]);
  const [removeSpecialAccess, setRemoveSpecialAccess] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [nameerror, setNameError] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [budgeterror, setBudgetError] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [groupstoadd, setGroupsToAdd] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [influenceradd, setInfluencerAdd] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [campName, setCampName] = useState({ name: '', id: '' });
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const AddInfluencers = async (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // reset error states
    setNameError("");
    setEmailError("");
    setBudgetError("");
    // validation
    if (inputsinf.nameinf === "") {
      setNameError("Name is required");
    }
    if (inputsinf.emailinf.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailPattern.test(inputsinf.emailinf)) {
      setEmailError("Invalid email format");
    }
    if (inputsinf.budgetinf === "") {
      setBudgetError("Budget is required");
    }
    try {
      const config = {
        method: "post",
        url: `${api_url}/influencers`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      let data;
      // if (selectedItem && selectedItem.id) {
      data = {
        name: inputsinf.nameinf,
        email: inputsinf.emailinf,
        budget: parseFloat(inputsinf.budgetinf),
        groupId: campName.id,
      };
      // } else {
      //   data = {
      //     name: inputsinf.nameinf,
      //     email: inputsinf.emailinf,
      //     budget: parseFloat(inputsinf.budgetinf),
      //   };
      // }
      config.data = data;
      const response = await axios(config);
      console.log(response.data);
      setInputsInf({
        emailinf: "",
        nameinf: "",
        budgetinf: "",
      });
      setInfluencerAdd(response?.data?.data);
      getAllInfluencers();
      setApiSuccess(true);
      toast.success("Influencer added successfully");
    } catch (error) {
      if (error) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred");
      }
      console.error('Error fetching project details:', error);
    }
  };
  const getGroupstoAdd = async (id, selectedname) => {
    try {
      const response = await axios.get(`${api_url}/groups/getNames?&limit=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGroupsToAdd(response?.data?.data);

    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };

  useEffect(() => {
    getGroupstoAdd(selectedItem.id, selectedItem.selectedname);
  }, []);



  useEffect(() => {
    AddInfluencers();
  }, []);
  const getAllInfluencers = async () => {
    try {
      const response = await axios.get(`${api_url}/influencers?offset=${`${page}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInfluencer(response?.data?.data);
      // setPageCount(response?.data?.totalCount)
      console.log(response?.data?.totalCount, "sdsdwed");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getAllInfluencers();
  }, [page]);

  const { emailinf, nameinf, budgetinf } = inputsinf;
  const handleChangeInf = (e) => {
    const { name, value } = e.target;
    setInputsInf((inputsinf) => ({ ...inputsinf, [name]: value }));
    if (name === "emailinf") {
      setEmailError("");
    } else if (name === "nameinf") {
      setNameError("");
    } else if (name === "budgetinf") {
      setBudgetError("");
    }
  };


  const AddGroups = async (e) => {
    e.preventDefault();
    setNameError("");
    setGroupError("");
    // Validation
    if (inputs.name === "") {
      setNameError("Name is required");
    }
    if (idArray.length >= 1) {
      console.log('lenght roti', idArray.length);
      // setGroupError("Group name is required");
      // return;
    } else {
      console.log('lenght roti 2222', idArray.length);
      // setGroupError("Group name is required");
    }
    try {
      const config = {
        method: 'post',
        url: `${api_url}/groups`,
        headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'application/json'
        },
        data: {
          name: name,
          influencerIds: idArray,
        }
      };
      const response = await axios(config);
      console.log(response);
      setInputs({
        name: "",
        groups: "",
      });
      setGroupsAdd(response?.data?.data);
      handleClose(false)
      toast.success("Campaign Created successfully");
      getGroups();
    } catch (error) {
      if (error) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred");
      }
      console.error('Error fetching project details:', error);
    }
  };


  useEffect(() => {
    AddGroups();
  }, []);
  const { name } = inputs;
  const handleChange = (e) => {
    console.log(e, "eeeeeeeeeee");
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    if (name === "name") {
      setNameError("")
    } else if (idArray === "groups") {
      setGroupError("")
    }
  };
  const handlePageChange = (e) => {
    console.log('eeeeeeeeeeeee', e.selected);
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const startIndex = page * itemsPerPage + 1;
  const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);





  console.log(page, groupslist, 'pageokokok');

  useEffect(() => {
    if (rend) {
      getGroups();
    }
  }, [rend])

  const getGroups = async () => {
    try {
      // setLoader(true)
      const response = await axios.get(`${api_url}/groups?offset=${`${page}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGroupsList(response?.data?.data);
      console.log(response?.data?.data, "333333333");
      setPageCount(response?.data?.data?.groupCount)
      setRend(false);
      console.log(response, "sssssssscecrf45");
      // setLoader(false)
    } catch (err) {
      // setLoader(false)
      if (err.response && err.response.status === 401) {
        navigate('/');
      } else {
        console.error('Error fetching influencers:', err);
        throw err;
      }
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getGroups()
  }, [page]);

  const [copiedItemId, setCopiedItemId] = useState(null);

  const textCopiedFun2 = (itemId) => {
    console.log(itemId, 'itemId');
    setCopiedItemId(itemId);
    setTimeout(() => {
      setCopiedItemId(null);
    }, 500);
  };


  const getAllNames = async (id, selectedname) => {
    try {
      const response = await axios.get(`${api_url}/influencers/getNames?&limit=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNames(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  console.log(names, "wwwwwwwwwwwwwwww");


  const handleSelect = (id, selectedname) => {
    setSelectedItem({ id, selectedname });
    getAllNames(id, selectedname);
  };

  useEffect(() => {
    getAllNames(selectedItem.id, selectedItem.selectedname);
  }, [selectedItem.id, selectedItem.selectedname]);

  const handleMultiselectRemove = (selectedList, removedItem) => {
    const selectedLeader = names.find((leader) => leader.name === removedItem);

    if (selectedLeader) {
      const selectedId = selectedLeader._id;
      const specialAccessExist = selectedItem?.id === selectedId;
      let res = idArray?.filter((item) => {
        return item !== selectedId
      })
      setIdArray(res)
      if (specialAccessExist) {
        // Handle your removal logic here
        // Filter out the item from your state or perform other actions
      }
    }
  };
  // console.log('idddddddddddddd', idArray);
  const handleMultiselectSelect = (selectedList, selectedName) => {
    const selectedLeader = names.find((leader) => leader.name === selectedName);

    if (selectedLeader) {
      const selectedId = selectedLeader._id;
      const specialAccessExist = selectedItem?.id === selectedId;
      setIdArray([...idArray, selectedId])
      if (!specialAccessExist) {
        // Handle your selection logic here
        // Add the item to your state or perform other actions
      }
    }

    return 0;
  };




  return (
    <>
      {
        routes ?
          <Groupsdetails setRoutes={setRoutes} />
          :
          <section className="dashboard">
            <div className="custom-container">
              <div className="main-heading">
                <h6>Campaigns</h6>
                <div className="mainheadingbtns">
                  <button className='btn-influencer' onClick={handleShow}>Create Campaign</button>
                </div>
              </div>
              <DataTable handleShow2={handleShow2} page={page} pageCount={pageCount} setRoutes={setRoutes} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} groupslist={groupslist}
                routes={routes} setCampName={setCampName} loader={loader} />
            </div>
          </section>
      }
      <Modal
        className="add-mega-modal global-modal-scss"
        show={show}
        onHide={() => { handleClose() }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="name"
                  name="name"
                  placeholder="Enter Campaign name"
                  value={name}
                  onChange={handleChange}
                  className={
                    "" +
                    (submitted && !name ? " is-invalid" : "")
                  }
                />
                <label>Name</label>
                {nameerror ? (
                  <p className="mt-2 text-danger email-error">{nameerror}</p>
                ) : null}
              </div>
            </div>
            <Multiselect
              className="multiselect-root"
              placeholder="Select Influencers"
              type="groups"
              name="groups"
              onChange={handleChange}
              value={idArray}
              // selectedValues={selectedItem?.selectedname ? [selectedItem.selectedname] : []}
              // className={
              //   "" +
              //   (submitted && !idArray ? " is-invalid" : "")
              // }
              isObject={false}
              onKeyPressFn={() => { }}
              onRemove={handleMultiselectRemove}
              onSearch={() => { }}
              onSelect={handleMultiselectSelect}
              customCloseIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              options={names?.map((item) => item.name || "")}
            // options={names}

            />
            {grouperror ? (
              <p className="mt-2 text-danger email-error">{grouperror}</p>
            ) : null}
            {/* <Dropdown onSelect={(id, event) => handleSelect(id, event.target.innerText)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedItem.selectedname && (
                  <span className="mainspan">
                    <p className="spanpara">{selectedItem.selectedname}</p>
                    <img src="\assets\influencers\close-circle.svg" alt="crossimg" className="crossimg" />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {names?.map((data, index) => (
                  <Dropdown.Item key={index} eventKey={data._id}>
                    {data.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <div className="twice-btn">
            <button
              onClick={handleClose}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              className="btn-add"
              onClick={(e) => AddGroups(e)}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="add-mega-modal global-modal-scss"
        show={show2}
        onHide={() => { handleClose2() }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Influencer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="name"
                  name="nameinf"
                  placeholder="Enter influencer’s Name"
                  value={nameinf}
                  onChange={handleChangeInf}
                  className={
                    "" +
                    (submitted && !nameinf ? " is-invalid" : "")
                  }
                />
                {nameerror ? (
                  <p className="mt-2 text-danger email-error">{nameerror}</p>
                ) : null}
                <label>Name</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="email"
                  name="emailinf"
                  placeholder="Enter influencer’s Email address"
                  value={emailinf}
                  onChange={handleChangeInf}
                  className={
                    "" +
                    (submitted && !emailinf ? " is-invalid" : "")
                  }
                />
                {emailerror ? (
                  <p className="mt-2 text-danger email-error">{emailerror}</p>
                ) : null}
                <label>Email</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="number"
                  name="budgetinf"
                  value={budgetinf}
                  onChange={handleChangeInf}
                  placeholder="Enter influencer’s budget"
                  className={
                    "" +
                    (submitted && !budgetinf ? " is-invalid" : "")
                  }
                />
                {budgeterror ? (
                  <p className="mt-2 text-danger email-error">{budgeterror}</p>
                ) : null}
                <label>Budget</label>
                <img src="\assets\dollar.svg" alt="dollarimg" className="dollarimg" />
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  name="Campaign"
                  value={campName?.name}
                  disabled
                  placeholder="Enter influencer’s budget"
                  className={
                    "" +
                    ("")
                  }
                />
                <label>Campaign</label>
              </div>
            </div>
            {/* <Dropdown>
              <Dropdown.Toggle>
                {selectedItem.selectedname || 'Select Campaign'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {groupstoadd?.map((data, index) => (
                  <Dropdown.Item
                    onClick={() => handleSelect(data._id, data.name)}
                    key={index}
                    eventKey={data._id}
                  >
                    {data?.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
             
            </Dropdown> */}
          </div>
          <div className="twice-btn">
            <button
              onClick={handleClose2}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              onClick={(e) => {
                // handleClose();
                handleShow1();
                AddInfluencers(e);
              }}
              className="btn-add"
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        apiSuccess && (
          <Modal
            className="add-mega-modal global-modal-scss"
            show={show1}
            onHide={handleClose1}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Influencer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="modalhead">New Influencer has been added</h6>
              <p className="modalpara">
                https://doptest.dop.org/id={influenceradd?.data?.referalCode}{' '}
                <CopyToClipboard
                  text={`${"https://doptest.dop.org/id=" + influenceradd?.data?.referalCode}`}
                  onCopy={() => textCopiedFun2(influenceradd)}
                >
                  <img className={copiedItemId === influenceradd && "copy-button-add"} src="/assets/copy.svg" alt="copy" />
                </CopyToClipboard>

              </p>
              <button
                onClick={() => {
                  handleClose1();
                  handleClose2();
                }}
                className="btn-login"
              >
                OKAY
              </button>
            </Modal.Body>
          </Modal>
        )
      }
    </>
  )
}

export default Groups