import React from 'react'
import "./forgot.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'

const NewPassword = () => {

    return (
        <>
            <div className="forgotpassword-wrapper">
                <section className="forgot-password">
                    <div className="custom-container">
                        {/* <Link to="/forgot"><button className='btn-back'><img src="\assets\forgot\arrow-left.svg" alt="img" className='img-fluid' />Back</button></Link> */}
                        <div className="parent-card">
                            <div className="upper-logo">
                                <img src="\assets\forgot\forgot-logo.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-content">
                                <h6>Create your new Password</h6>
                                <p>
                                Please enter your new password
                                </p>
                                <div className="option-field">
                                    <div className="material-textfield">
                                        <input
                                            placeholder="New Password"
                                            type="text"
                                        />
                                        <label>New Password</label>
                                    </div>
                                    <img src="\eye.svg" alt="img" className='img-fluid password-icon' />
                                </div>
                                <div className="option-field">
                                    <div className="material-textfield">
                                        <input
                                            placeholder="Confirm New Password"
                                            type="text"
                                        />
                                        <label>Confirm New Password</label>
                                    </div>
                                    <img src="\eye.svg" alt="img" className='img-fluid password-icon' />
                                </div>
                                <Link className='w-100' to="/success">
                                    <button className='btn-send'>Reset Password</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}

export default NewPassword;
