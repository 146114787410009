import React from 'react'
import './forgot.scss'
import { Link } from 'react-router-dom'

const Emailsent = () => {
    return (
        <>
            <div className="forgotpassword-wrapper">
                <section className="forgot-password">
                    <div className="custom-container">
                        <div className="parent-card">
                            <div className="upper-logo">
                                <img src="\assets\forgot\forgot-logo.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-content newcontentt">
                                <h6>Email has been Sent!</h6>
                                <p className='text-center'>Please check your inbox and click on the received link to reset your password.</p>
                            
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Emailsent