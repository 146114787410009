import React, { useEffect, useState } from 'react'
import Navbar from '../header/Navbar'
import './influencerdetail.scss'
import DataTable from './DataTable/DataTable'
import { api_url } from '../../../utils/Apiurl'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import DataTableInfluencersExcel from './DataTable/DataTableInfluencersExcel'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useNavigate } from 'react-router-dom';
import { Dropdown, Modal } from 'react-bootstrap'
import AddInfluModal from './AddInfluModal.js'
import { toast } from 'react-toastify'
const Influencerdetail = ({ setindexwait, routes, setRoutes, setchangepage }) => {
    const [showInfu, handleShowInfu] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ id: '', selectedname: '' });
    const [sidebar, setSidebar] = useState(false);
    const [influencerbyid, setInfluencerById] = useState([]);
    const [influencerusers, setInfluencerUsers] = useState([]);
    const [deactivate, setDeactivate] = useState([]);
    const [loader, setLoader] = useState([]);
    const [id, setId] = useState('');
    const token = localStorage.getItem("miangInfu");
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState([]);
    const [isChecked, setChecked] = useState(false);
    const [influencerDetailExcel, setInfluencerDetailExcel] = useState([]);
    const itemsPerPage = 10;
    const [groupstoadd, setGroupsToAdd] = useState([]);
    const navigate = useNavigate();

    const getInfluencerUsers = async (_id) => {
        try {
            const config = {
                method: 'get',
                url: `${api_url}/influencers/${id}/stats`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            setInfluencerUsers(response?.data?.data?.data);
        } catch (error) {
            console.error('Error fetching project details:', error);
            setLoader(false);
        }
    };
    const influencerDelete = async (id) => {
        try {
            const config = {
                method: 'patch',
                url: `${api_url}/influencers/delete/${id}`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            toast.success('Influencer deleted successfully')
            handleClose3()
            goBack()
            setLoader(false);
        } catch (error) {
            toast.error(error.response.data.message);
            console.error('Error fetching project details:', error);
            setLoader(false);
        }
    };

    const handleSelect = (id, selectedname) => {
        setSelectedItem({ id, selectedname });
        // setGroupError("")
    };

    const getInfluencerdetails = async (_id) => {
        try {
            const config = {
                method: 'get',
                url: `${api_url}/influencers/byId/${id}?offset=${`${page}`}&limit=10`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            setInfluencerById(response?.data?.data);
            setChecked(response?.data?.data?.influencer?.isActive);
            setPageCount(response?.data?.data?.count)
            console.log(response?.data?.data?.influencer?.isActive, "df440000ff0");
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error('Error fetching influencers:', error);
                throw error;
            }
            setLoader(false);
        }
    };

    const getInfluencerdetailsExcel = async (_id) => {
        try {
            const config = {
                method: 'get',
                url: `${api_url}/influencers/byId/${id}?offset=${`${page}`}&limit=10`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            setInfluencerDetailExcel(response?.data?.data);
        } catch (error) {
            console.error('Error fetching project details:', error);
            setLoader(false);
        }
    };

    const handleToggle = async () => {
        try {
            setChecked(!isChecked)
            const config = {
                method: 'patch',
                url: `${api_url}/influencers/${id}`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id,
                    isActive: !isChecked,
                }
            };
            const response = await axios(config);
            console.log('alsdfj', response);
            setDeactivate(response?.data?.data);
            // setChecked(response?.data?.data?.influencers?.[0]?.isActive);
        } catch (error) {
            console.error('Error fetching project details:', error);
            setLoader(false);
        }
    };
    const handlePageChange = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage);
    };

    useEffect(() => {
        console.log('dddddddd', id)
        var val = window.location.href;
        val = new URL(val);
        setId(val.searchParams.get("id"));
        console.log(id, "sssssss");
        localStorage.setItem("currentId", val.searchParams.get("id"));
        // window.scroll(0, 0);
        if (id) {
            getInfluencerdetails();
            getInfluencerdetailsExcel();
            getInfluencerUsers();
        }
    }, [id, page]);

    const startIndex = page * itemsPerPage + 1;
    const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);
    console.log(influencerbyid, "newwwwwww");

    const goBack = () => {
        navigate(-1);
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    useEffect(() => {
        if (!token) {
            localStorage.removeItem("miangInfu");
            navigate("/");
        }
    }, [token])
    return (
        <>
            <section className="dashboard">
                <div className="custom-container">
                    <button onClick={goBack} className="btn-backing">
                        <img
                            src="\assets\forgot\arrow-left.svg"
                            alt="img"
                            className="img-fluid"
                        />
                        Back
                    </button>

                    <div className="influencerstatsdetail ">
                        <div className="influencerstatmain influencerstatmainfour">
                            <div className="innerinfluencerstat">
                                <img src="\assets\influencers\cardtwo.svg" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">Total Users</h6>
                                <p className="influencerpara">{influencerusers?.totalUsers || '0'}</p>
                            </div>
                            <div className="innerinfluencerstat">
                                <img src="\assets\influencers\cardfour.png" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">Users with 100% steps</h6>
                                <p className="influencerpara">{influencerusers?.totalCompletedUsers || '0'}</p>
                            </div>
                            <div className="innerinfluencerstat">
                                <img src="\assets\influencers\cardfive.png" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">Average time per user</h6>
                                <p className="influencerpara">
                                    {influencerusers?.averageCompleteTime !== undefined
                                        ? `${(influencerusers?.averageCompleteTime / (1000 * 60)).toFixed(0)} minutes`
                                        : '0 minutes'
                                    }
                                </p>
                            </div>
                            <div className="innerinfluencerstat">
                                <img src="\assets\dollarhand.svg" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">CPA</h6>
                                <p className="influencerpara">
                                    {/* $2.53 */}
                                    ${influencerusers?.budget || '0'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="main-heading">
                        <h6>Influencer’s Detail</h6>
                        <div className="rightsidebtns">
                            <div className="parent-toggle-switch-custom">
                                <div class="custom-toggle">
                                    <input
                                        type="checkbox"
                                        class="checkbox"
                                        id="checkbox"
                                        checked={isChecked}
                                        onChange={() => handleToggle()}
                                    />
                                    <label class="switch" for="checkbox">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                                <p>Deactivate this code</p>
                            </div>
                            <button onClick={() => handleShowInfu(true)} className="editbutton"><img src="\assets\iconssss\edit-2.svg" alt="editimg" className="editimg" />Edit</button>
                            <button onClick={handleShow3} className="delbutton"><img src="\assets\iconssss\trash.svg" alt="delimg" className="delimg" />Delete</button>
                        </div>
                    </div>
                    <div className="detailbox">
                        <div className="innerdetailbox">
                            {console.log('influencerbyid?.influencers?.[0]', influencerbyid?.influencer)}
                            <h6 className="detailhead">Name</h6>
                            <p className="detailpara">{influencerbyid?.influencer?.name}</p>
                        </div>
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Email address</h6>
                            <p className="detailpara">{influencerbyid?.influencer?.email}</p>
                        </div>
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Link</h6>
                            <p className="detailpara">https://doptest.dop.org/id={influencerbyid?.influencer?.referalCode}</p>
                        </div>
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Campaign</h6>
                            <p className="detailpara">
                                {/* {influencerbyid?.totalRewards} */}
                                {influencerbyid?.influencer?.groupId?.name}
                            </p>
                        </div>
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Budget</h6>
                            <p className="detailpara" style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '22px' }}>$</span>
                                {influencerbyid?.influencer?.budget}
                            </p>
                        </div>
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Internal marketing account</h6>
                            <p className="detailpara" style={{ display: 'flex', alignItems: 'center' }}>
                                {influencerbyid?.influencer?.isInternal ? 'Yes' : "No"}
                            </p>
                        </div>
                    </div>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn-backing exportbtn"
                        table="table-to-xls"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Export To Excel"
                    />
                    {/* <button className="btn-backing exportbtn">Export To Excel</button> */}
                    <DataTable influencerbyid={influencerbyid} pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} />
                    <DataTableInfluencersExcel influencerbyid={influencerDetailExcel} pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage}
                        routes={routes} excelId={'table-to-xls'} />
                </div>
            </section>


            <Modal
                className="add-mega-modal global-modal-scss"
                show={show}
                onHide={() => { handleClose() }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-mega-div">
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="name"
                                    name="name"
                                    placeholder="Enter influencer’s Name"

                                />

                                <label>Name</label>
                            </div>
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter influencer’s Email address"

                                />

                                <label>Email</label>
                            </div>
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="number"
                                    name="budget"
                                    placeholder="Enter influencer’s budget"

                                />

                                <label>Budget</label>
                                <img src="\assets\dollar.svg" alt="dollarimg" className="dollarimg" />
                            </div>
                        </div>

                        {/* <Multiselect
              className="multiselect-root"
              placeholder="Select Campaign"
              // selectedValues={selectedItem?.selectedname ? [selectedItem.selectedname] : []}
              isObject={false}
              onKeyPressFn={() => { }}
              onRemove={handleMultiselectRemove}
              onSearch={() => { }}
              onSelect={handleMultiselectSelect}
              customCloseIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              options={groupstoadd?.map((item) => item?.name)}
            />  */}
                        <Dropdown>
                            <Dropdown.Toggle>
                                {selectedItem.selectedname || 'Select Campaign'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {groupstoadd?.map((data, index) => (
                                    <Dropdown.Item
                                        onClick={() => handleSelect(data._id, data.name)}
                                        key={index}
                                        eventKey={data._id}
                                    >
                                        {data?.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                            {/* {grouperror ? (
                <p className="text-danger email-error">{grouperror}</p>
              ) : null} */}
                        </Dropdown>
                    </div>
                    <div className="checkboxmain">
                        <div class="content">
                            <label class="checkBox">
                                <input type="checkbox" id="ch1" />
                                <div class="transition"></div>
                            </label>
                        </div>
                        <p className="checkboxpara">Internal marketing account</p>
                    </div>
                    <div className="twice-btn">
                        <button
                            onClick={handleClose}
                            className="btn-cancel"
                        >
                            Back
                        </button>
                        <button
                            onClick={() => {
                                handleShow2();
                                handleClose();
                            }
                            }
                            className="btn-add"
                        >
                            Save Changes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                className="add-mega-modal global-modal-scss"
                show={show2}
                onHide={() => { handleClose2() }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="editpara">Changes saved successfully</p>
                    <button onClick={handleClose2} className="okaybtn">Okay</button>
                </Modal.Body>
            </Modal>

            <Modal
                className="add-mega-modal global-modal-scss"
                show={show3}
                onHide={() => { handleClose3() }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img src="\assets\iconssss\delimg.svg" alt="delimg" className="delimg text-center" />
                    </div>

                    <h6 className="delhead">Are you sure you want to delete this?</h6>
                    <p className="delpara">This will delete it permanently. You cannot undo this action.</p>
                    <div className="twice-btn">
                        <button
                            onClick={handleClose3}
                            className="btn-cancel"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => influencerDelete(influencerbyid?.influencer._id)}
                            className="btn-del"
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <AddInfluModal showInfu={showInfu} handleShowInfu={handleShowInfu} influencerDetail={influencerbyid?.influencer} getInfluencerdetails={getInfluencerdetails} />
        </>
    )
}

export default Influencerdetail