import { useState, useEffect } from 'react'
import './users.scss'
import DataTable from './DataTable/DataTable'
import Userdetail from './Userdetails/Userdetail'
import { api_url } from '../../../utils/Apiurl'
import { toast } from 'react-toastify';
import axios from 'axios'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DataTableUserExcel from './DataTable/DataTableUserExcel'
import { useNavigate } from 'react-router-dom';


const Users = ({ routes, setRoutes }) => {

  const [userstats, setUserStats] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const [allusersexcel, setAllUsersExcel] = useState([]);
  const token = localStorage.getItem("miangInfu");
  const itemsPerPage = 10;
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const startIndex = page * itemsPerPage + 1;
  const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);
  const [searchTerm, setSearchTerm] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();


  const getAllUsers = async () => {
    setLoader(true)
    try {
      const response = await axios.get(`${api_url}/rewards?${searchTerm && `search=${searchTerm}&`}offset=${`${searchTerm ? 0 : page}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllUsers(response?.data?.data);
      setPageCount(response?.data?.count);
      setLoader(false)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/');
      } else {
        console.error('Error fetching influencers:', err);
        throw err;
      }
      console.error('Error fetching users:', err);
      setLoader(false)
    }
  };
  const getAllUsersExcel = async () => {
    try {
      const response = await axios.get(`${api_url}/rewards?offset=${`${page}`}&limit=50`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllUsersExcel(response?.data?.data);
    } catch (err) {
      console.error('Error fetching users:', err);

      if (err.response) {
        console.error('Response status:', err.response.status);
        console.error('Response data:', err.response.data);
      }
    }
  };



  useEffect(() => {
    getAllUsersExcel()
  }, [])
  // useEffect(() => {
  //   getAllUsers()
  // }, [page, searchTerm])


  let timeoutId;
  useEffect(() => {
    // Use a local variable to store the timeout ID
    let timeoutId;

    // Clear the previous setTimeout on rapid changes
    clearTimeout(timeoutId);

    // Set a new timeout for the current input
    timeoutId = setTimeout(() => {
      getAllUsers();
    }, 1000); // Adjust the delay time as needed

    // Cleanup function to cancel the current setTimeout on component unmount or when the input changes
    return () => clearTimeout(timeoutId);
  }, [page, searchTerm]);


  const getUsersStats = async () => {
    try {
      const response = await axios.get(`${api_url}/rewards/getAllStats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserStats(response?.data?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getUsersStats();
  }, []);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };



  return (
    <>
      {
        routes ?
          <Userdetail routes={routes} setRoutes={setRoutes} />
          :
          <section className='main-user'>
            <div className="custom-container">
              <div className='parentbox'>
                <div className='mainusercard'>
                  <div className='mainheading'>
                    <img src='\assets\people.svg' alt='img' className='img-fluid' />
                    <p>Total Users</p>
                    <h4>{userstats?.totalUsers}</h4>
                  </div>
                </div>
                <div className='mainusercard formbl'>
                  <div className='mainheading'>
                    <img src='\assets\chart.svg' alt='img' className='img-fluid' />
                    <p>Users with 100% steps</p>
                    <h4>{userstats?.allRewardsStats}</h4>
                  </div>
                </div>
                <div className='mainusercard formbl'>
                  <div className='mainheading'>
                    <img src='\assets\Frame888.svg' alt='img' className='img-fluid' />
                    <p>Average time per user</p>
                    <h4>
                      {userstats?.averageTimePerUser !== undefined
                        ? `${(userstats?.averageTimePerUser / (1000 * 60)).toFixed(0)} minutes`
                        : '0 minutes'
                      }

                    </h4>
                  </div>
                </div>

              </div>
              <div className='mobilecard d-none'>
                <div className='mblparent'>
                  <div className='mainusercard '>
                    <div className='mainheading'>
                      <img src='\assets\Frame888.svg' alt='img' className='img-fluid' />
                      <p>Average time per user</p>
                      <h4>

                        {/* {userstats?.averageTimePerUser !== undefined
                        ? `${(userstats?.averageTimePerUser / 60000).toFixed(0)} minutes`
                        : '0 minutes'
                      } */}
                        {userstats?.averageTimePerUser !== undefined
                          ? `${(userstats?.averageTimePerUser / (1000 * 60)).toFixed(0)} minutes`
                          : '0 minutes'
                        }


                      </h4>
                    </div>
                  </div>
                  <div className='mainusercard '>
                    <div className='mainheading'>
                      <img src='\assets\chart.svg' alt='img' className='img-fluid' />
                      <p>Users with 100% steps</p>
                      <h4>{userstats?.allRewardsStats}</h4>
                    </div>
                  </div>
                </div>

              </div>
              <div className="main-heading">
                <h6>All Users</h6>
                {/* <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn-influencer"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Export To Excel"
                /> */}
                {/* <button>Export To Excel</button> */}
                <div className="searchinputt">
                  <input
                    type="text"
                    name="text"
                    placeholder='Search Users by Referal code'
                    value={searchTerm}
                    onChange={(e) => { setSearchTerm(e.target.value) }}
                  // onChange={handleInputChange}
                  />
                  <img src="\search-normal.svg" alt="searchimg" className="searchimg" />
                </div>
              </div>

              <DataTable pageCount={pageCount} handlePageChange={handlePageChange} page={page} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} allusers={allusers} routes={routes} setRoutes={setRoutes} loader={loader} />
              <DataTableUserExcel allusers={allusersexcel} routes={routes} setRoutes={setRoutes} excelId={'table-to-xls'} />
            </div>
          </section>
      }
    </>

  )
}

export default Users