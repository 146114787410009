import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ReactCountryFlag from "react-country-flag"

class Countrychart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                data: [45000, 40000, 35000, 30000, 25000, 20000, 15000, 10000, 5000, 3000],
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    background: '#000',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#000'],
                    },
                },
                xaxis: {
                    categories: [
                        <ReactCountryFlag
                        countryCode="US"
                        svg
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                        title="US"
                    />
                    ],
                    labels: {
                        style: {
                            colors: ['#fff'],
                        },
                    }
                },
                yaxis: {
                    labels: {
                      style: {
                        colors: ['#fff'],
                      },
                    },
                  },
                  grid: {
                    show: false, // Hide the grid lines
                  },
                  stroke: {
                    show: false, // Hide the stroke lines
                  },
                  fill: {
                    colors: ['#FFF'], // Set bar color to black
                  },
            },
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        );
    }
}

export default Countrychart;