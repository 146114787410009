import React, { useState, useEffect } from "react";
// import "./datatable.scss"
import { Link } from 'react-router-dom'
import tableup from '../../../../../assets/tableup.svg'
import tabledown from '../../../../../assets/tabledown.svg'
import { Accordion } from "react-bootstrap";
import axios from 'axios'
import ReactPaginate from "react-paginate";
import moment from "moment/moment";


const DataTableUserDetailExcel = ({ page, pageCount, handlePageChange, itemsPerPage, endIndex, startIndex, userdetail, userdetailexcel, excelId }) => {
  const [changetext, setchangetext] = useState(false)
  console.log(userdetail, "dwdfe2");




  return (
    <>
      <section className="datatable d-none" >
        <div className="table-responsive">
          <table class="table" id={excelId}>
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date/Time  <div className="filter-btn">


                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    IP/Country <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 1 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 2 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 3 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 4 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 5 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 6 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 7 <div className="filter-btn">

                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 8 <div className="filter-btn">

                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {userdetailexcel?.users?.map((data, index) => {
                const createdAtDate = new Date(data?.createdAt);
                const createdAtDate2 = new Date(data?.follow_Us_On_Twitter?.completedAt);
                const createdAtDate3 = new Date(data?.claim_Sepolia?.completedAt);
                const createdAtDate4 = new Date(data?.claim_Dop?.completedAt);
                const createdAtDate5 = new Date(data?.claim_Testnet_Assets?.completedAt);
                const createdAtDate6 = new Date(data?.encrypt_Assets?.completedAt);
                const createdAtDate7 = new Date(data?.send_Assets?.completedAt);
                const createdAtDate8 = new Date(data?.decrypt_Assets?.completedAt);
                const createdAtDate9 = new Date(data?.threeInvites?.completedAt);
                const dateString = createdAtDate.toLocaleDateString();
                const dateString2 = createdAtDate2.toLocaleDateString();
                const dateString3 = createdAtDate3.toLocaleDateString();
                const dateString4 = createdAtDate4.toLocaleDateString();
                const dateString5 = createdAtDate5.toLocaleDateString();
                const dateString6 = createdAtDate6.toLocaleDateString();
                const dateString7 = createdAtDate7.toLocaleDateString();
                const dateString8 = createdAtDate8.toLocaleDateString();
                const dateString9 = createdAtDate9.toLocaleDateString();
                console.log(data?.[index]?.follow_Us_On_Twitter?.isCompleted, "eeefef4f4g");
                return (
                  <tr key={index}>
                    <td>

                      {dateString}
                      {moment(data?.createdAt)
                        .utc()
                        .utcOffset("+02:00")
                        .format("hh:mm A")}

                    </td>
                    <td>

                      {data?.ip}
                      {data?.location}

                    </td>
                    <td>
                      {data?.follow_Us_On_Twitter?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          {dateString2}

                          {" "}
                          {moment(data?.follow_Us_On_Twitter?.completedAt)
                            .utc()
                            .utcOffset("+02:00")
                            .format("hh:mm A")}

                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>

                    <td>
                      {data?.claim_Sepolia?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          {dateString3}

                          {" "}
                          {moment(data?.claim_Sepolia?.completedAt)
                            .utc()
                            .utcOffset("+02:00")
                            .format("hh:mm A")}

                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                    <td>
                      {data?.claim_Dop?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          {dateString4}
                          {" "}
                          {moment(data?.claim_Dop?.completedAt)
                            .utc()
                            .utcOffset("+02:00")
                            .format("hh:mm A")}

                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                    <td>
                      {data?.claim_Testnet_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString5}</p>
                          <p>
                            {" "}
                            {moment(data?.claim_Testnet_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                    <td>
                      {data?.encrypt_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString6}</p>
                          <p>
                            {" "}
                            {moment(data?.encrypt_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                    <td>
                      {data?.send_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString7}</p>
                          <p>
                            {" "}
                            {moment(data?.send_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                    <td>
                      {data?.decrypt_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString8}</p>
                          <p>
                            {" "}
                            {moment(data?.decrypt_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                    <td>
                      {data?.threeInvites?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString9}</p>
                          <p>
                            {" "}
                            {moment(data?.threeInvites?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        "☒"
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="footer-content">
            <div className="left-f">
              <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
        <div className="mobiledesignss  d-none">

          <div className="footer-content">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default DataTableUserDetailExcel