import React, { useState, useEffect } from 'react'
import { Accordion, Dropdown, Modal, Nav, ProgressBar } from 'react-bootstrap'
import { api_url } from '../../../../utils/Apiurl'
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";
import { toast } from 'react-toastify';
import ReactApexChart from 'react-apexcharts';
import Countrychart from './Countrychart'
import Navbar from '../../header/Navbar'
import DataTable from './DataTable/DataTable';
import { Link } from 'react-router-dom';
import Influencerdetail from '../../Influencerdetail/Influencerdetail';
import { useNavigate } from 'react-router-dom';

const Groupsdetails = ({ setindexwait, routes, setRoutes }) => {
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
  });
  const [show, setShow] = useState(false);
  const [nameerror, setNameError] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [influencer, setInfluencer] = useState([]);
  const [influenceradd, setInfluencerAdd] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const token = localStorage.getItem("miangInfu");
  const [page, setPage] = useState(0);
  const [pagenew, setPageNew] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [pageCountnew, setPageCountNew] = useState([]);
  const [countrieslist, setCountrieslist] = useState([]);
  const [groupinfluencers, setGroupInfluencers] = useState([]);
  const [stats, setStats] = useState([]);
  const [campaingsdetails, setCampaingsDetails] = useState([]);
  const [id, setId] = useState('');
  const navigate = useNavigate();


  console.log(page, "countrieslist");

  const itemsPerPage = 10;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);


  const AddInfluencers = async (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // Reset error states
    setNameError("");
    setEmailError("");
    // Validation
    if (inputs.name === "") {
      setNameError("Name is required");
    }
    if (inputs.email.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailPattern.test(inputs.email)) {
      setEmailError("Invalid email format");
    }
    try {
      const config = {
        method: 'post',
        url: `${api_url}/influencers`,
        headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'application/json'
        },
        data: {
          name: name,
          email: email,
        }
      };
      const response = await axios(config);
      console.log(response);
      setInfluencerAdd(response?.data?.data);
      getAllInfluencers()
      setApiSuccess(true);
      toast.success("Influencer added succesfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("An error occurred");
      }
      console.error('Error fetching project details:', error);
      setLoader(false);
    }
  };
  useEffect(() => {
    AddInfluencers();
  }, []);


  const getAllInfluencers = async () => {
    try {
      const response = await axios.get(`${api_url}/influencers?offset=${`${page}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInfluencer(response?.data?.data);
      // setPageCount(response?.data?.totalCount)
      console.log(response?.data?.totalCount, "sdsdwed");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  // useEffect(() => {
  //   getAllInfluencers();
  // }, [page]);



  const handlePageChange = (e) => {
    console.log('eeeeeeeeeeeee', e.selected);
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const handlePageChangeNew = (e) => {
    console.log('eeeeeeeeeeeee', e.selected);
    const selectedPage = e.selected;
    setPageNew(selectedPage);
  };
  const { email, name } = inputs;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    if (name === "email") {
      setEmailError("")
    } else if (name === "name") {
      setNameError("")
    }
  };

  const startIndex = page * itemsPerPage + 1;
  const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);
  const startIndexnew = pagenew * itemsPerPage + 1;
  const endIndexnew = Math.min((pagenew + 1) * itemsPerPage, pageCountnew);
  const [copiedItemId, setCopiedItemId] = useState(null);

  const textCopiedFun2 = (itemId) => {
    console.log(itemId, 'itemId');
    setCopiedItemId(itemId);
    setTimeout(() => {
      setCopiedItemId(null);
    }, 500);
  };


  const getGroupCountries = async () => {
    try {
      const response = await axios.get(`${api_url}/groups/${id}/topCountryWithGroup?offset=${`${pagenew}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCountrieslist(response?.data?.data?.countries);
      setPageCountNew(response?.data?.data?.count)
      console.log(response?.data?.data?.count, "kkkkkk");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  const getGroupInfluencers = async () => {
    try {
      const response = await axios.get(`${api_url}/groups/${id}/topInfluencersWithGroup?offset=${`${pagenew}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGroupInfluencers(response?.data?.data?.influencer);
      setPageCountNew(response?.data?.data?.count)
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };


  // const getAllUsers = async () => {
  //   try {
  //     const response = await axios.get(`${api_url}/groups/topUser?limit=10`);
  //     setUsers(response?.data?.data);
  //     // setPageCount(response?.data?.totalCount)
  //     // console.log(response?.data?.totalCount, "sdsdwed");
  //   } catch (err) {
  //     console.error('Error fetching influencers:', err);
  //   }
  // };
  // useEffect(() => {
  //   getAllUsers()
  // }, [])

  const getStats = async () => {
    try {
      const response = await axios.get(`${api_url}/groups/${id}/getMyStats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStats(response?.data?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  const getCampaingsDetails = async () => {
    // setLoader(true)
    try {
      const response = await axios.get(`${api_url}/groups/${id}/getCampaignDetails?offset=${`${page}`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCampaingsDetails(response?.data?.data?.data);
      setPageCount(response?.data?.data?.data?.count)
      console.log(response?.data?.data?.data?.totalInfluencer, "dd4d44444");
      // setLoader(false)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/');
      } else {
        console.error('Error fetching influencers:', err);
        throw err;
      }
      console.error('Error fetching campaign details:', err);
      // setLoader(false)
    }
  };
  console.log(campaingsdetails, "r4444444");

  const [activeTab, setActiveTab] = useState('Countries');
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    console.log(id, "sssssss");
    localStorage.setItem("currentId", val.searchParams.get("id"));
    if (id) {
      getStats()
      getCampaingsDetails()
    }
  }, [id, page]);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    console.log(id, "sssssss");
    localStorage.setItem("currentId", val.searchParams.get("id"));
    if (id) {
      // if (activeTab !== 'Countries' && activeTab !== 'Influencers') {
      //   getCampaingsDetails();
      // }
      if (activeTab === 'Countries') {
        getGroupCountries()
      }
      if (activeTab === 'Influencers') {
        getGroupInfluencers()
      }

    }
  }, [id, pagenew, activeTab]);


  const [selectedItems, setSelectedItems] = useState("Countries");
  const items = ["Countries", "Influencers"];

  const [changetext, setchangetext] = useState(false)
  const [changetext3, setchangetext3] = useState(false)

  const [changepage, setchangepage] = useState(false);


  const handleSelecttab = (eventKey) => {
    setPageNew(0)
    setPageCountNew([])
    setActiveTab(eventKey);
  };

  const result2 = function calculatePercentageChange(data) {
    if (
      data?.totalRewards !== undefined &&
      data?.weekRewardsCount !== undefined &&
      data?.weekRewardsCount !== 0
    ) {
      const percentageChange = ((data?.totalRewards - data?.weekRewardsCount) / Math.abs(data?.weekRewardsCount)) * 100;
      return `${percentageChange.toFixed(1)}%`;
    } else {
      return '0%';
    }
  };
  const percentageDifference = ((groupinfluencers?.fourteenDaysRewardsCount - groupinfluencers?.weekRewardsCount) / groupinfluencers?.weekRewardsCount) * 100 || '0';
  const sign = percentageDifference >= 0 ? '+' : '-';
  return (
    <>

      {
        changepage ?
          <Influencerdetail setchangepage={setchangepage}
          />
          :
          <section className="dashboard">
            <div className="custom-container">
              <Link
                // to="/dashboard"
                onClick={() => {
                  setRoutes(false);
                }}
              >
                <button className="btn-backing">
                  <img
                    src="\assets\forgot\arrow-left.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  Back
                </button>
              </Link>
              <div className="influencerstats">
                <div className="influencerstatmain groupstatss">
                  <div className="innerinfluencerstat">
                    <img src="\assets\influencers\cardone.svg" alt="influencerstatimg" className="influencerstatimg" />
                    <h6 className="influencerstathead">Total Influencers</h6>
                    <p className="influencerpara">{stats?.totalInfluencers || "0"}</p>
                  </div>
                  <div className="innerinfluencerstat">
                    <img src="\assets\influencers\cardtwo.svg" alt="influencerstatimg" className="influencerstatimg" />
                    <h6 className="influencerstathead">Total Users</h6>
                    <p className="influencerpara">{stats?.totalUsers || "0"}</p>
                  </div>
                  <div className="innerinfluencerstat">
                    <img src="\assets\influencers\cardthree.svg" alt="influencerstatimg" className="influencerstatimg" />
                    <h6 className="influencerstathead">Average User Per Influencers</h6>
                    <p className="influencerpara"><p className="influencerpara">
                      {stats?.totalInfluencers > 0
                        ? (stats?.totalUsers / stats?.totalInfluencers).toFixed(0)
                        : '0'
                      }
                    </p>
                    </p>
                  </div>
                  <div className="innerinfluencerstat">
                    <img src="\assets\influencers\cardfour.png" alt="influencerstatimg" className="influencerstatimg" />
                    <h6 className="influencerstathead">Users with 100% steps</h6>
                    <p className="influencerpara">{stats?.totalCompletedUsers || "0"}</p>
                  </div>
                  <div className="innerinfluencerstat">
                    <img src="\assets\influencers\cardfive.png" alt="influencerstatimg" className="influencerstatimg" />
                    <h6 className="influencerstathead">Average time per user</h6>
                    <p className="influencerpara">
                      {/* {stats?.averageCompleteTime !== undefined
                        ? `${(stats?.averageCompleteTime / (60 * 1000)).toFixed(1)} minutes`
                        : '0 minutes'
                      } */}
                      {stats?.averageCompleteTime !== undefined ? `${(stats?.averageCompleteTime / 60000).toFixed(0)} minutes` : '0 minutes'}
                    </p>

                  </div>
                  <div className="innerinfluencerstat">
                    <img src="\assets\dollarhand.svg" alt="influencerstatimg" className="influencerstatimg" />
                    <h6 className="influencerstathead">Average CPA/Influencer</h6>
                    <p className="influencerpara">
                      {stats?.totalBudget !== undefined && stats?.totalInfluencers !== undefined
                        ? `$${(stats.totalBudget / stats.totalInfluencers).toFixed(2)}`
                        : '0'}
                    </p>
                  </div>
                </div>
              </div>
              <Nav variant="pills" activeKey={activeTab} onSelect={handleSelecttab} className='groupdetailpillsss'>
                <Nav.Item>
                  <Nav.Link eventKey="Countries">Countries</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Influencers">
                    Influencers
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="chartsmain">
                <div className="innerchart">


                  {/* <Countrychart /> */}
                  {activeTab === "Countries" && (
                    <>
                      <div className="mainbigchart">
                        <div className="new-accordionchart">
                          <div className="upper-body">
                            <div className="inner-text">
                              <h6>Country</h6>
                              <p>User</p>
                              <p>7 day</p>
                            </div>
                            {countrieslist?.map((data, index) => {
                              // if (!data?._id?.location || data?.weekRewardsCount === undefined || data?.count === undefined || data?.count === 0) {
                              //   return null;
                              // }
                              const percentage =
                                data?.weekRewardsCount !== undefined && data?.count !== undefined && data?.count !== 0
                                  ? (((data?.count - data?.weekRewardsCount) / data?.weekRewardsCount) * 100).toFixed(1).replace(/\.0$/, '')
                                  : '0';
                              return (
                                <div className="inner-texted mb-4" key={index}>
                                  <h6>{data?._id}</h6>
                                  <p>{data?.count}</p>
                                  <p>
                                    <img src="\assets\influencers\arrow-up.svg" alt="img" /> {percentage === 'Infinity' ? '0' : percentage}%
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="footer-contenttt">
                        <div className="left-f">
                          <h6>{startIndexnew}-{endIndexnew} OF {pageCountnew}</h6>
                        </div>
                        <div className="right-f">
                          {/* <nav aria-label="Page navigation example">
                                       <ul class="pagination">
                                         <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                                         <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                                         <li class="page-item"><a class="page-link" href="#">4</a></li>
                                         <li class="page-item"><a class="page-link" href="#">5</a></li>
                                         <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                                       </ul>
                                     </nav> */}
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item border border-white text-white bg-white"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={Math.ceil(pageCountnew / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChangeNew}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pagenew}
                          ></ReactPaginate>
                        </div>
                      </div>
                    </>
                  )}
                  {activeTab === "Influencers" && (
                    <>
                      <div className="mainbigchart">
                        <div className="new-accordionchart">
                          <div className="upper-body">
                            <div className="inner-text">
                              <h6>NAME</h6>
                              <p className='centertext'>Total users(7d)</p>
                              <p className='centertext'>direct users(7d)</p>
                              <p className='centertext'>indirect users(7d)</p>
                            </div>
                            {groupinfluencers?.map((data, index) => {
                              // if (!data?.name || data?.weekRewardsCount === undefined || data?.totalRewards === undefined || data?.totalRewards === 0) {
                              //   return null;
                              // }

                              // let asd = (data?.weekRewardsCount !== undefined && data?.totalRewards !== undefined && data?.totalRewards !== 0)
                              //   ? ((data?.allStepTrue / data?.totalRewards) * 100).toFixed(0)
                              //   : 0

                              return (
                                <>
                                  <div key={index} className="inner-texted mb-4">
                                    <h6>{data?.name}</h6>
                                    <p className='centertext'>
                                      {data?.directUsers + data?.inDirectUsers}
                                      {/* <span className={data?.inDirectUsers >= 0 ? 'greentext' : 'redtext'}>
                                        {data?.inDirectUsers >= 0 ? '+' : '-'}
                                        {result2(data)}
                                      </span> */}
                                    </p>
                                    <p className='centertext'>{data?.directUsers} <span className='redtext'>{sign}{Math.abs(percentageDifference).toFixed(1)}%</span></p>
                                    <p className='centertext'>
                                      {/* <img src="\assets\influencers\arrow-up.svg" alt="img" />{' '} */}
                                      {data?.inDirectUsers}
                                      {/* <span className='greentext'>+11.7%</span> */}
                                    </p>
                                  </div>
                                  {/* <ProgressBar now={asd ? asd : 0} /> */}
                                </>
                              );
                            })}

                          </div>

                        </div>
                      </div>
                      <div className="footer-contenttt">
                        <div className="left-f">
                          <h6>{startIndexnew}-{endIndexnew} OF {pageCountnew}</h6>
                        </div>
                        <div className="right-f">
                          {/* <nav aria-label="Page navigation example">
                                       <ul class="pagination">
                                         <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                                         <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                                         <li class="page-item"><a class="page-link" href="#">4</a></li>
                                         <li class="page-item"><a class="page-link" href="#">5</a></li>
                                         <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                                       </ul>
                                     </nav> */}
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item border border-white text-white bg-white"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={Math.ceil(pageCountnew / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChangeNew}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pagenew}
                          ></ReactPaginate>
                        </div>
                      </div>
                    </>
                  )}

                </div>
                {/* <div className="innerchart">
                <h6 className="chartheading">Top 10 Groups by most users</h6>
                <div className="mainbigchart">
                  <div className="new-accordionchart">
                    <div className="upper-body">
                      <div className="inner-text">
                        <h6>Groups</h6>
                        <p>User</p>
                        <p>7 day</p>
                      </div>
                      {users?.slice(0, 5).map((data, index) => (
                        <>
                          <div className="inner-texted">
                            <h6>{data?.name}</h6>
                            <p>{data?.influencerCount}</p>
                            <p><img src="\assets\influencers\arrow-up.svg" alt="img" /> 85.7%</p>
                          </div>
                          <ProgressBar now={40} />
                        </>
                      ))}
                    </div>
                    <div className="bottom-body">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header onClick={() =>
                            setchangetext2(!changetext2)
                          }>
                            {changetext2 ? <p>More</p> : <p>Less</p>} Info{" "}
                            {changetext2 ? <img
                              src="\assets\influencers\add.svg"
                              alt="img"
                              className="img-fluid"
                            /> : <img
                              src="\assets\influencers\minus.svg"
                              alt="img"
                              className="img-fluid"
                            />}
                          </Accordion.Header>
                          {users?.slice(5).map((data, index) => (
                            <Accordion.Body>
                              <div className="inner-texted">
                                <h6>{data?.name}</h6>
                                <p>{data?.influencerCount}</p>
                                <p><img src="\assets\influencers\arrow-down.svg" alt="img" /> 85.7%</p>
                              </div>
                              <ProgressBar now={40} />
                            </Accordion.Body>
                          ))}
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
              <div className="main-heading">
                <h6>Campaign Details</h6>
              </div>
              <DataTable changepage={changepage} setchangepage={setchangepage} campaingsdetails={campaingsdetails} pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} loader={loader} />
              <div className='d-flex justify-content-end'>
              </div>
            </div>
          </section>
      }
    </>
  )
}

export default Groupsdetails