import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import DataTable from '../Userdetails/DataTable/DataTable';
import { api_url } from '../../../../utils/Apiurl';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from 'axios';
import DataTableUserDetailExcel from './DataTable/DataTableUserDetailExcel';
import { useNavigate } from 'react-router-dom';

const Userdetail = ({ setRoutes }) => {

    const [id, setId] = useState('');
    const [userstat, setUserStat] = useState([]);
    const [userdetail, setUserDetail] = useState([]);
    const [userdetailexcel, setUserDetailExcel] = useState([]);
    const token = localStorage.getItem("miangInfu");
    const [isChecked, setChecked] = useState(false);
    const [deactivate, setDeactivate] = useState([]);
    const [page, setPage] = useState(0);
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState([]);
    const navigate = useNavigate();


    const getUsersStats = async (_id) => {
        try {
            const config = {
                method: 'get',
                url: `${api_url}/influencers/${id}/user-stats`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            setUserStat(response?.data?.data);
        } catch (error) {
            console.error('Error fetching project details:', error);
            // setLoader(false);
        }
    };

    const getUsersDetail = async (_id) => {
        try {
            const config = {
                method: 'get',
                url: `${api_url}/rewards/${id}/user-refered?offset=${`${page}`}&limit=10`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            setUserDetail(response?.data?.data);
            setPageCount(response?.data?.data?.count)
            console.log(response?.data?.data?.count, "dedeeffe");
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error('Error fetching influencers:', error);
                throw error;
            }
            console.error('Error fetching project details:', error);
            // setLoader(false);
        }
    };
    const getUsersDetailExcel = async (_id) => {
        try {
            const config = {
                method: 'get',
                url: `${api_url}/rewards/${id}/user-refered?offset=0&limit=10`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id
                }
            };
            const response = await axios(config);
            setUserDetailExcel(response?.data?.data);
        } catch (error) {
            console.error('Error fetching project details:', error);
            // setLoader(false);
        }
    };



    useEffect(() => {
        console.log('dddddddd', id)
        var val = window.location.href;
        val = new URL(val);
        setId(val.searchParams.get("id"));
        console.log(id, "sssssss");
        localStorage.setItem("currentId", val.searchParams.get("id"));
        // window.scroll(0, 0);
        if (id) {
            getUsersDetail();
        }
    }, [id, page]);
    useEffect(() => {
        console.log('dddddddd', id)
        var val = window.location.href;
        val = new URL(val);
        setId(val.searchParams.get("id"));
        console.log(id, "sssssss");
        localStorage.setItem("currentId", val.searchParams.get("id"));
        // window.scroll(0, 0);
        if (id) {
            getUsersStats();
            getUsersDetailExcel();
        }
    }, [id]);

    const handleToggle = async () => {
        try {
            setChecked(!isChecked)
            const config = {
                method: 'patch',
                url: `${api_url}/influencers/${id}`,
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    _id: id,
                    isActive: !isChecked,
                }
            };
            const response = await axios(config);
            console.log('alsdfj', response);
            setDeactivate(response?.data?.data);
            setChecked(response?.data?.data?.isActive);
        } catch (error) {
            console.error('Error fetching project details:', error);
            // setLoader(false);
        }
    };


    const handlePageChange = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage);
    };

    const startIndex = page * itemsPerPage + 1;
    const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);

    return (
        <>
            <section className="dashboard">
                <div className="custom-container">
                    <Link
                        onClick={() => {
                            setRoutes(false);
                        }}
                    >
                        <button className="btn-backing">
                            <img
                                src="\assets\forgot\arrow-left.svg"
                                alt="img"
                                className="img-fluid"
                            />
                            Back
                        </button>
                    </Link>
                    <div className="influencerstatsdetail">
                        <div className="influencerstatmain groupstatssss">
                            <div className="innerinfluencerstat">
                                <img src="\assets\influencers\cardtwo.svg" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">Total Users</h6>
                                <p className="influencerpara">{userstat?.totalUsers || "0"}</p>
                            </div>
                            <div className="innerinfluencerstat">
                                <img src="\assets\influencers\cardfour.png" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">Users with 100% steps</h6>
                                <p className="influencerpara">{userstat?.totalCompletedUsers || "0"}</p>
                            </div>
                            <div className="innerinfluencerstat">
                                <img src="\assets\influencers\cardfive.png" alt="influencerstatimg" className="influencerstatimg" />
                                <h6 className="influencerstathead">Average time per user</h6>
                                <p className="influencerpara">

                                    {/* {userstat?.averageTimePerUser !== undefined
                                    ? `${(userstat?.averageTimePerUser * 60).toFixed(0)} minutes`
                                    : '0 minutes'} */}
                                    {userstat?.averageCompleteTime !== undefined
                                        ? `${(userstat?.averageCompleteTime / (1000 * 60)).toFixed(0)} minutes`
                                        : '0 minutes'
                                    }

                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="main-heading">
                        <h6>User’s Detail</h6>
                        {/* <div className="parent-toggle-switch-custom">
                            <div class="custom-toggle">
                                <input
                                    type="checkbox"
                                    class="checkbox"
                                    id="checkbox"
                                    checked={isChecked}
                                    onChange={() => handleToggle()}
                                />
                                <label class="switch" for="checkbox">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <p>Deactivate this code</p>
                        </div> */}
                    </div>
                    <div className="detailbox">
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Email address</h6>
                            <p className="detailpara">{userstat?.email}</p>
                        </div>
                        <div className="innerdetailbox">
                            <h6 className="detailhead">Link</h6>
                            <p className="detailpara">https://doptest.dop.org/id={userstat?.referalCode}</p>
                        </div>
                    </div>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn-backing exportbtn"
                        table="table-to-xls"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Export To Excel"
                    />
                    {/* <button className="detailbtn">Export To Excel</button> */}
                    <DataTable userdetail={userdetail} pageCount={pageCount} page={page} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} />
                    <DataTableUserDetailExcel userdetailexcel={userdetailexcel} excelId={'table-to-xls'} />
                </div>
            </section>
        </>
    )
}

export default Userdetail