import React, { useState, useEffect } from "react";
import "./datatable.scss"
import { Link } from 'react-router-dom'
import tableup from '../../../../assets/tableup.svg'
import tabledown from '../../../../assets/tabledown.svg'
import { Accordion } from "react-bootstrap";
import { api_url } from "../../../../utils/Apiurl";
import axios from 'axios'
import ReactPaginate from "react-paginate";
import moment from "moment/moment";


const DataTable = ({ influencerbyid, page, pageCount, handlePageChange, itemsPerPage, endIndex, startIndex }) => {
  const [changetext, setchangetext] = useState(false)
  console.log(influencerbyid, "dwdfe2");




  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date/Time  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    IP/Country <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 1 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 2 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 3 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 4 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 5 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 6 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 7 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 8 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {influencerbyid?.rewards?.map((reward, index) => {
                const createdAtDate = new Date(reward?.createdAt);
                const createdAtDate2 = new Date(reward?.follow_Us_On_Twitter?.completedAt);
                const createdAtDate3 = new Date(reward?.claim_Sepolia?.completedAt);
                const createdAtDate4 = new Date(reward?.claim_Dop?.completedAt);
                const createdAtDate5 = new Date(reward?.claim_Testnet_Assets?.completedAt);
                const createdAtDate6 = new Date(reward?.encrypt_Assets?.completedAt);
                const createdAtDate7 = new Date(reward?.send_Assets?.completedAt);
                const createdAtDate8 = new Date(reward?.decrypt_Assets?.completedAt);
                const createdAtDate9 = new Date(reward?.threeInvites?.completedAt);
                const dateString = createdAtDate.toLocaleDateString();
                const dateString2 = createdAtDate2.toLocaleDateString();
                const dateString3 = createdAtDate3.toLocaleDateString();
                const dateString4 = createdAtDate4.toLocaleDateString();
                const dateString5 = createdAtDate5.toLocaleDateString();
                const dateString6 = createdAtDate6.toLocaleDateString();
                const dateString7 = createdAtDate7.toLocaleDateString();
                const dateString8 = createdAtDate8.toLocaleDateString();
                const dateString9 = createdAtDate9.toLocaleDateString();
                return (
                  <tr key={index}>
                    <td>
                      <div className="twice-text">
                        <h6>{dateString}</h6>
                        <p>  {moment(reward?.createdAt)
                          .utc()
                          .utcOffset("+02:00")
                          .format("hh:mm A")}</p>
                      </div>
                    </td>
                    <td>
                      <div className="twice-text">
                        <h6>{influencerbyid?.rewards?.[index]?.ip}</h6>
                        <p>{influencerbyid?.rewards?.[index]?.location}</p>
                      </div>
                    </td>
                    <td>
                      {influencerbyid?.rewards?.[index]?.follow_Us_On_Twitter?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString2}</p>
                          <p>
                            {" "}
                            {moment(reward?.follow_Us_On_Twitter?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>
                    <td>
                      {influencerbyid?.rewards?.[index]?.claim_Sepolia?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString3}</p>
                          <p>
                            {" "}
                            {moment(reward?.claim_Sepolia?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>
                    <td>
                      {influencerbyid?.rewards?.[index]?.claim_Dop?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString4}</p>
                          <p>
                            {" "}
                            {moment(reward?.claim_Dop?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>

                    <td>
                      {influencerbyid?.rewards?.[index]?.claim_Testnet_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString5}</p>
                          <p>
                            {" "}
                            {moment(reward?.claim_Testnet_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>
                    <td>
                      {influencerbyid?.rewards?.[index]?.encrypt_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString6}</p>
                          <p>
                            {" "}
                            {moment(reward?.encrypt_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>
                    <td>
                      {influencerbyid?.rewards?.[index]?.send_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString7}</p>
                          <p>
                            {" "}
                            {moment(reward?.send_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>

                    <td>
                      {influencerbyid?.rewards?.[index]?.decrypt_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString8}</p>
                          <p>
                            {" "}
                            {moment(reward?.decrypt_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>
                    <td>
                      {influencerbyid?.rewards?.[index]?.threeInvites?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString9}</p>
                          <p>
                            {" "}
                            {moment(reward?.threeInvites?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                    </td>
                    {/* <td>
                    <div className="status-red">
                      <h6 className="statushead-two">Incomplete</h6>
                      <p className="datepara-two">0/3</p>
                    </div>
                    </td> */}
                    {/* <td><img src={influencerbyid?.rewards?.[index]?.claim_Dop ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td> */}
                    {/* <td><img src={influencerbyid?.rewards?.[index]?.claim_Sepolia ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td> */}
                    {/* <td><img src={influencerbyid?.rewards?.[index]?.claim_Testnet_Assets ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td>
                    <td><img src={influencerbyid?.rewards?.[index]?.encrypt_Assets ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td>
                    <td><img src={influencerbyid?.rewards?.[index]?.send_Assets ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td>
                    <td><img src={influencerbyid?.rewards?.[index]?.decrypt_Assets ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td>
                    <td><img src={influencerbyid?.rewards?.[index]?.follow_Us_On_Twitter ? "/assets/tick-circle.svg" : "/assets/close-circle.svg"} /></td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="footer-content">
            <div className="left-f">
              <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
        <div className="mobiledesignss  d-none">
        {influencerbyid?.rewards?.map((reward, index) => {
            const createdAtDate = new Date(reward?.createdAt);
            const createdAtDate2 = new Date(reward?.follow_Us_On_Twitter?.completedAt);
            const createdAtDate3 = new Date(reward?.claim_Sepolia?.completedAt);
            const createdAtDate4 = new Date(reward?.claim_Dop?.completedAt);
            const createdAtDate5 = new Date(reward?.claim_Testnet_Assets?.completedAt);
            const createdAtDate6 = new Date(reward?.encrypt_Assets?.completedAt);
            const createdAtDate7 = new Date(reward?.send_Assets?.completedAt);
            const createdAtDate8 = new Date(reward?.decrypt_Assets?.completedAt);
            const createdAtDate9 = new Date(reward?.threeInvites?.completedAt);
            const dateString = createdAtDate.toLocaleDateString();
            const dateString2 = createdAtDate2.toLocaleDateString();
            const dateString3 = createdAtDate3.toLocaleDateString();
            const dateString4 = createdAtDate4.toLocaleDateString();
            const dateString5 = createdAtDate5.toLocaleDateString();
            const dateString6 = createdAtDate6.toLocaleDateString();
            const dateString7 = createdAtDate7.toLocaleDateString();
            const dateString8 = createdAtDate8.toLocaleDateString();
            const dateString9 = createdAtDate9.toLocaleDateString();
            return (
              <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
                <div className="upper-body">
                  <div className="inner-text">
                    <h6>Date</h6>
                    <p>{dateString}</p>

                  </div>
                  <div className="inner-text">
                    <h6>Time</h6>
                    <p>  {moment(reward?.createdAt)
                      .utc()
                      .utcOffset("+02:00")
                      .format("hh:mm A")}</p>
                  </div>
                  <div className="inner-text">
                    <h6>IP</h6>
                    <p>{influencerbyid?.rewards?.[index]?.ip}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Country</h6>
                    <p>{influencerbyid?.rewards?.[index]?.location}</p>
                  </div>
                </div>
                <div className="bottom-body">
                  <Accordion>
                    <Accordion.Item eventKey={reward?._id}>
                      <Accordion.Header onClick={() =>
                        setchangetext(!changetext)
                      }>
                        {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        {/* for new flow with date */}
                        <div className="inner-textt">
                          <p>Date/Time</p>
                          <h6>{dateString}</h6>
                        </div>
                        <div className="inner-textt">
                          <p>Step 1</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.follow_Us_On_Twitter?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString2}</p>
                          <p>
                            {" "}
                            {moment(reward?.follow_Us_On_Twitter?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/tick-circle.svg" alt="tick-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 2</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.claim_Sepolia?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString3}</p>
                          <p>
                            {" "}
                            {moment(reward?.claim_Sepolia?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/tick-circle.svg" alt="tick-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 3</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.claim_Dop?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString4}</p>
                          <p>
                            {" "}
                            {moment(reward?.claim_Dop?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/tick-circle.svg" alt="tick-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 4</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.claim_Testnet_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString5}</p>
                          <p>
                            {" "}
                            {moment(reward?.claim_Testnet_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/tick-circle.svg" alt="tick-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 5</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.encrypt_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString6}</p>
                          <p>
                            {" "}
                            {moment(reward?.encrypt_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/close-circle.svg" alt="close-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 6</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.send_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString7}</p>
                          <p>
                            {" "}
                            {moment(reward?.send_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/close-circle.svg" alt="close-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 7</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.decrypt_Assets?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString8}</p>
                          <p>
                            {" "}
                            {moment(reward?.decrypt_Assets?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/close-circle.svg" alt="close-circle" /> */}
                        </div>
                        <div className="inner-textt">
                          <p>Step 8</p>
                          <div className="status">
                          {influencerbyid?.rewards?.[index]?.threeInvites?.isCompleted ? (
                        <div className="status">
                          <h6 className="statushead">Completed</h6>
                          <p className="datepara">{dateString9}</p>
                          <p>
                            {" "}
                            {moment(reward?.threeInvites?.completedAt)
                              .utc()
                              .utcOffset("+02:00")
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <img src="/assets/close-circle.svg" alt="Not Completed" />
                      )}
                          </div>
                          {/* <img src="/assets/close-circle.svg" alt="close-circle" /> */}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            )
          })}
          <div className="footer-content">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default DataTable