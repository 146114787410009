import '../../App.scss';
import React, { useState, useEffect } from "react";
import Navbar from './header/Navbar';
import Dashboard from './Dashboard/Dashboard';
import Groups from './Groups/Groups';
import { useHistory } from "react-router-dom";
import Users from './User.js/Users';
import axios from 'axios';
import { api_url } from '../../utils/Apiurl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Allinfluencer from './Allinfluencer/Allinfluencer';

function Landing() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // const [routes, setRoutes] = useState(false);
  const indexvv = localStorage.getItem("indexvalue");
  const [indexwait, setindexwait] = useState(0);
  const [rend, setRend] = useState(false);
  const hitfunctionss = (asd) => {
    setindexwait(asd);
    localStorage.setItem("indexvalue", asd);
  };

  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {

    if (indexvv == "0") {
      setindexwait(0);
    } else if (indexvv == "1") {
      setindexwait(1);
    }
    else if (indexvv == "2") {
      setindexwait(2);
    }
    else if (indexvv == "3") {
      setindexwait(3);
    }
  }, []);

  const [routes, setRoutes] = useState(() => {
    const storedRoutes = sessionStorage.getItem('routes');
    return storedRoutes ? JSON.parse(storedRoutes) : false;
  });
  useEffect(() => {
    sessionStorage.setItem('routes', JSON.stringify(routes));
  }, [routes]);

  const handleLogout = () => {
    try {
      localStorage.removeItem("miangInfu");
      localStorage.removeItem("refreshToken");
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Logout failed. Please try again.");
    }
  };

  return (
    <>
      <Navbar
        sidebar={sidebar}
        setSidebar={setSidebar}
        setindexwait={setindexwait}
        indexwait={indexwait}
        setRoutes={setRoutes}
        setRend={setRend}
      />
      {/* <Dashboard /> */}
      {indexwait == 0 ? (
        <>
          <Dashboard
            setRoutes={setRoutes}
            routes={routes}
          />
        </>
      ) : indexwait == 1 ? (
        <>
        <Allinfluencer
             setRoutes={setRoutes}
             routes={routes}
        />
        </>
      ) : indexwait == 2 ? (
        <>
          <Groups
            setRoutes={setRoutes}
            routes={routes}
            setRend={setRend}
            rend={rend}
          />
        </>
      )
        : indexwait == 3 ? (
          <>
            <Users
              setRoutes={setRoutes}
              routes={routes} />
          </>
        ) : ""
      }
      {
        sidebar && <div className="custom-sidebar" >
          <div className="topdiv d-none">
            <img src="\assets\sidebar\logoimg.svg" alt="logoimg" className="logoimg" onClick={() => {
                  hitfunctionss(0);
                  setSidebar(false);
                  setRoutes(false);
                }} />
            <img src="\assets\sidebar\toggleimg.svg" alt="toggleimg" className="toggleimg" onClick={() => {
                  setSidebar(false);
                }} />
          </div>
          <ul>
            <li>
              <a
                className={indexwait === 0 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(0);
                  setSidebar(false);
                  setRoutes(false);
                }}
              ><img src="\assets\sidebar\dashboard.svg" alt="img" className='img-fluid' />Dashboard</a>
            </li>
            <li>
              <a
                className={indexwait === 1 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(1);
                  setSidebar(false);
                  setRoutes(false);
                }}
              ><img src="\assets\profile-2user.svg" alt="img" className='img-fluid' />Influencers</a>
            </li>
            <li>
              <a
                className={indexwait === 2 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(2);
                  setSidebar(false);
                  setRoutes(false);
                }}
              ><img src="\assets\sidebar\people.svg" alt="img" className='img-fluid' />Campaigns</a>
            </li>
            <li>
              <a
                className={indexwait === 3 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(3);
                  setSidebar(false);
                  setRoutes(false);
                }}
              ><img src="\assets\sidebar\user.svg" alt="img" className='img-fluid' />Users</a>
            </li>
          </ul>
          <button className="nav-btn d-none">Add Influencer</button>
          <button className="nav-btn d-none">Create Campaign</button>
          <button onClick={handleLogout} className="logout-btn absolutecenter"><img src="\assets\logout-icon.svg" alt="img" className="img-fluid" />Logout</button>
        </div>
      }
    </>
  );
}

export default Landing;
