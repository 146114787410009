import { useState } from "react";
import "./login.scss";
import { api_url } from "../../utils/Apiurl";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';



const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };
  const [showPass, setShowPass] = useState("password");
  const [emailerror, setEmailError] = useState("");
  const [passerror, setPassError] = useState("");
  const [loader, setLoader] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const userLogin = (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Reset error states
    setEmailError("");
    setPassError("");

    // Validation
    if (inputs.email.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailPattern.test(inputs.email)) {
      setEmailError("Invalid email format");
    }

    if (inputs.password === "") {
      setPassError("Password is required");
    }

    if (!emailerror && !passerror) {
      const data = JSON.stringify({
        email: inputs.email,
        password: inputs.password,
        rememberMe: rememberMe,
      });
      if (inputs.email && inputs.password) {
        const config = {
          method: "post",
          url: `${api_url}/auth/users/signin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(async function (response) {
            localStorage.setItem("miangInfu", response?.data?.data?.accessToken);
            localStorage.setItem("accessToken", 'eyJh6IjY1NWRlMmZjNmMyNjhiMTUyOTMyZTkxMyIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwNDU2Mzc2NiwiZXhwIjoxNzA0NjUwMTY2LCJhdWQiOiI2NTVkZTJmYzZjMjY4YjE1MjkzMmU5MTMiLCJpc3MiOiJ0b21pZG9wLmNvbSJ9.s3uEzWWaYgZYWJHsNPjEC2kDg8b0GCkzzaEE_KAY-90');
            localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
            navigate("/dashboard");
            setLoader(false);
            toast.success("User Logged in Successfully");
          })
          .catch(async function (error) {
            setLoader(false);
            if (error.response && error.response.status === 401) {
              if (error.response.data?.message === "Token Expired") {
                try {
                  const refreshToken = localStorage.getItem("refreshToken");
                  const refreshConfig = {
                    method: "post",
                    url: `${api_url}/auth/users/refresh-token`,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: JSON.stringify({ refresh_token: refreshToken }),
                  };
                  const refreshResponse = await axios(refreshConfig);
                  localStorage.setItem("miangInfu", refreshResponse?.data?.access_token);
                  if (refreshResponse?.data?.refresh_token) {
                    localStorage.setItem("refreshToken", refreshResponse?.data?.refresh_token);
                  }
                  return axios(config);
                } catch (refreshError) {
                  navigate("/");
                  toast.error("Jwt Expired. Please log in again.");
                }
              } else {
                toast.error(error?.response?.data?.message);
              }
            } else {
              toast.error("An error occurred");
            }
          });
      }
    }
  };


  const { email, password } = inputs;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    if (name === "email") {
      setEmailError("")
    } else if (name === "password") {
      setPassError("")
    }
  };


  return (
    <>
      <section className="login">
        <div className="login-card">
          <img src="\loginlogo.svg" alt="img" className='img-fluid' />
          <h6>log into your Admin account</h6>
          <div className="option-field">
            <div className="material-textfield">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                className={
                  "" +
                  (submitted && !email ? " is-invalid" : "")
                }
                placeholder="Enter you email"
              />
              <label>Email</label>
              {emailerror ? (
                <p className="mt-2 text-danger email-error">{emailerror}</p>
              ) : null}
            </div>
          </div>
          <div className="option-field">
            <div className="material-textfield">
              <input
                type={showPass}
                name="password"
                placeholder="Enter you password"
                value={password}
                onChange={handleChange}
                className={
                  "" +
                  (submitted && !password ? " is-invalid" : "")
                }
              />
              {passerror ? (
                <p className="text-danger">{passerror}</p>
              ) : null}
              <label>Password</label>
            </div>
            <img
              onClick={() => {
                showPass === "password"
                  ? setShowPass("text")
                  : setShowPass("password");
              }}
              src="\eye.svg" alt="img"
              className='img-fluid password-icon'
            />
          </div>
          <div className="custom-checkbox">
            <div className="form-group">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="rememberMe">Keep me logged in</label>
            </div>
            {/* <Link to="/forgot">
              <a href="#">Forgot Password?</a>
            </Link> */}
          </div>
          <Link className="w-100" to="/dashboard">
            <button onClick={(e) => userLogin(e)} className='btn-login'>Log In</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Login;