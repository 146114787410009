import React, { useState, useEffect, useRef } from "react";
import "./datatable.scss"
import { Link } from 'react-router-dom'
import tableup from '../../../../../assets/tableup.svg'
import tabledown from '../../../../../assets/tabledown.svg'
import { Accordion } from "react-bootstrap";
// import { api_url } from "../../../../utils/Apiurl";
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";

const DataTable = ({ influencer, page, pageCount, handlePageChange, itemsPerPage, endIndex, startIndex, setRoutes, setchangepage, campaingsdetails, loader }) => {
  const [changetext, setchangetext] = useState(false)

  const [copy, setCopy] = useState(null);
  const textCopiedFun2 = (index) => {
    setCopy(index);
    setTimeout(() => {
      setCopy(false);
    }, 500);
  };

  // console.log(campaingsdetails, "ddddddddddddd");



  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Name/Email  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                {/* <th>
                  <div className="parent-tag">
                    Group  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">
                    Link/Status <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Users <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 1 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 2 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 3 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 4 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 5 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 6 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 7 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 8 <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {campaingsdetails?.influencer?.map((data, index) => (
                <tr>
                  <td>
                    <div className="twice-text">
                      <h6>{data?.name}</h6>
                      <p>{data?.email}</p>
                    </div>
                  </td>
                  <td> <div className="twice-text">
                    <h6>  https://doptest.dop.org/id={data?.referalCode}
                      <CopyToClipboard
                        text={"  https://doptest.dop.org/id=" + data?.referalCode}
                        onCopy={(e) => textCopiedFun2(index)}
                      >
                        <img className={copy === index && "copy-button"} src="\assets\copy.svg" alt="copy" style={{ cursor: "pointer" }} />
                      </CopyToClipboard>
                    </h6>
                    <p className={data?.isActive ? "yellow" : "red"}>
                      {data?.isActive ? "Active" : "Inactive"}
                    </p>
                  </div></td>
                  <td>
                    {data?.totalRewards || '0'}
                  </td>
                  <td>{data?.task1 || '0'}</td>
                  <td>{data?.task2 || '0'}</td>
                  <td>{data?.task3 || '0'}</td>
                  <td>{data?.task4 || '0'}</td>
                  <td>{data?.task5 || '0'}</td>
                  <td>{data?.task6 || '0'}</td>
                  <td>{data?.task7 || '0'}</td>
                  <td>{data?.task8 || '0'}</td>
                  {/* <td>200</td>
                  <td>300</td>
                  <td>100</td>
                  <td>100</td>
                  <td>200</td>
                  <td>100</td> */}
                  <td><div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="\assets\more-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <Link
                          class="dropdown-item"
                          to={`/influencerdetail?id=${data?._id}`}
                        // onClick={() => {
                        //   setchangepage(true);
                        // }}
                        >
                          <img
                            src="\assets\document-text.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          View Details
                        </Link>
                      </li>
                    </ul>
                  </div></td>
                </tr>
              ))
              }
            </tbody>
          </table>
          <div className="footer-content">
            <div className="left-f">
              {/* {loader || */}
                <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
              {/* } */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>

        <div className="mobiledesignss  d-none">
          {influencer?.map((data, index) => (
            <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
              <div className="upper-body">
                <div className="inner-text">
                  <h6>Name</h6>
                  <p>{data?.name}</p>
                </div>
                <div className="inner-text">
                  <h6>Email</h6>
                  <p>{data?.email}</p>
                </div>
                <div className="inner-text">
                  <h6>Link</h6>
                  <p>{data?.link}
                    <CopyToClipboard
                      text={data?.link}
                      onCopy={(e) => textCopiedFun2(index)}
                    >
                      <img className={copy === index && "copy-button"} src="\assets\copy.svg" alt="copy" />
                    </CopyToClipboard>
                  </p>
                </div>
                <div className="inner-text">
                  <h6>Status</h6>
                  <p className="yellow">{data?.isActive}</p>
                </div>
              </div>
              <div className="bottom-body">
                <Accordion>
                  <Accordion.Item eventKey={data?._id}>
                    <Accordion.Header onClick={() =>
                      setchangetext(!changetext)
                    }>
                      {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-textt">
                        <p>Users</p>
                        <h6>{data?.totalRewards}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 1</p>
                        <h6>{data?.task1}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 2</p>
                        <h6>{data?.task2}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 3</p>
                        <h6>{data?.task3}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 4</p>
                        <h6>{data?.task4}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 5</p>
                        <h6>{data?.task5}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 6</p>
                        <h6>{data?.task6}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 7</p>
                        <h6></h6>
                      </div>
                      <Link
                        to={`/influencerdetail?id=${data?._id}`}
                        onClick={() => {
                          setRoutes(true);
                        }}
                      >
                        <button className="view-btn">View Details</button>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          ))
          }
          <div className="footer-content">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={!loader ? Math.ceil(pageCount / itemsPerPage) : 0}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default DataTable