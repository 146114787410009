import { useState } from "react";
import "./forgot.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../utils/Apiurl";
import { toast } from 'react-toastify';

const Forgot = () => {

  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState("");
  const token = localStorage.getItem("miangInfu");

  const ForgetPassword = async () => {
    var config = {
      method: "post",
      url: `${api_url}/auth/users/forget-password`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: {
        email: email,
      },
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        toast.success("Password has been send to your email");
      })
      .catch(function (error) {
        setLoader(false);
      });
  }


  return (
    <>
      <div className="forgotpassword-wrapper">
        <section className="forgot-password">
          <div className="custom-container">
            {/* <Link to="/login">
              <button className="btn-back">
                <img
                  src="\assets\forgot\arrow-left.svg"
                  alt="img"
                  className="img-fluid"
                />
                Back
              </button>
            </Link> */}
            <div className="parent-card">
              <div className="upper-logo">
                <img
                  src="\assets\forgot\forgot-logo.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="bottom-content">
                <h6>Forgot password?</h6>
                <p>
                Enter your registered email below to receive password reset instructions.
                </p>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      placeholder="Enter Your Email Address"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email Address</label>
                  </div>
                </div>
                <Link to="/emailsent" className="w-100">
                  <button  className="btn-send">
                    Send
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Forgot;
