import React, { useState, useEffect, useRef } from "react";
import "./datatable.scss"
import { Link, Routes } from 'react-router-dom'
import { Accordion } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";
import moment from "moment";

const ReferalDataExcel = ({ influencer, page, pageCount, handlePageChange, itemsPerPage, endIndex, startIndex, setRoutes, routes, excelId }) => {
    const [changetext, setchangetext] = useState(false)

    const [copy, setCopy] = useState(null);
    const textCopiedFun2 = (index) => {
        setCopy(index);
        setTimeout(() => {
            setCopy(false);
        }, 500);
    };
    // console.log(data?.rewards?.createdAt,"ddfuuuuu88");
    console.log(influencer, "ddfu88");




    return (
        <>
            <section className="datatable d-none">
                <div className="table-responsive">
                    <table class="table" id={excelId}>
                        <thead>
                            <tr>

                                {/* <th>
                  <div className="parent-tag">
                    Campaign
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Link/Status
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Users
                  </div>
                </th> */}

                                <th>
                                    <div className="parent-tag">
                                        Name
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Referal Code
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Direct Users
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Indirect Users
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Level 1                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Level 2
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Level 3
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Level 4
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {influencer?.map((data, index) => (
                                <tr>
                                    <td>{data?.name}</td>
                                    <td>https://doptest.dop.org/id={data?.referalCode}</td>
                                    <td>{data?.directReferral}</td>
                                    <td>{data?.indirectReferal}</td>
                                    <td>{data?.data3?.[0]}</td>
                                    <td>{data?.data3?.[1]}</td>
                                    <td>{data?.data3?.[2]}</td>
                                    <td>{data?.data3?.[3]}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                    <div className="footer-content">
                        <div className="left-f">
                            <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
                        </div>
                        <div className="right-f">
                            {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
                            <ReactPaginate
                                previousLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M10 13L5 8L10 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                nextLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 13L10 8L5 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                pageClassName="page-item border border-white text-white bg-white"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(pageCount / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}
                            ></ReactPaginate>
                        </div>
                    </div>
                </div>

                <div className="mobiledesignss  d-none">
                    {influencer?.map((data, index) => (
                        <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
                            <div className="upper-body">
                                <div className="inner-text">
                                    <h6>Name</h6>
                                    <p>{data?.name}</p>
                                </div>
                                <div className="inner-text">
                                    <h6>Email</h6>
                                    <p>{data?.email}</p>
                                </div>
                                <div className="inner-text">
                                    <h6>Link</h6>
                                    <p>{data?.link}
                                        <CopyToClipboard
                                            text={data?.link}
                                            onCopy={(e) => textCopiedFun2(index)}
                                        >
                                            <img className={copy === index && "copy-button"} src="\assets\copy.svg" alt="copy" />
                                        </CopyToClipboard>
                                    </p>
                                </div>
                                <div className="inner-text">
                                    <h6>Status</h6>
                                    <p className="yellow">{data?.isActive}</p>
                                </div>
                            </div>
                            <div className="bottom-body">
                                <Accordion>
                                    <Accordion.Item eventKey={data?._id}>
                                        <Accordion.Header onClick={() =>
                                            setchangetext(!changetext)
                                        }>
                                            {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                                            <img
                                                src="\assets\arrow-down.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="inner-textt">
                                                <p>Total Users</p>
                                                <h6>{data?.totalRewards}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 1</p>
                                                <h6>{data?.task1}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 2</p>
                                                <h6>{data?.task2}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 3</p>
                                                <h6>{data?.task3}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 4</p>
                                                <h6>{data?.task4}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 5</p>
                                                <h6>{data?.task5}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 6</p>
                                                <h6>{data?.task6}</h6>
                                            </div>
                                            <div className="inner-textt">
                                                <p>Step 7</p>
                                                <h6>{data?.task7}</h6>
                                            </div>
                                            <Link
                                                // to={`/influencerdetail?id=${data?._id}`}
                                                onClick={() => {
                                                    setRoutes(!routes);
                                                }}
                                            >
                                                <button className="view-btn">View Detailss</button>
                                            </Link>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    ))
                    }
                    <div className="footer-content">
                        <div className="left-f">
                            {/* <h6>SHOWING 1-10 OF 145</h6> */}
                        </div>
                        <div className="right-f">
                            {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
                            <ReactPaginate
                                previousLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M10 13L5 8L10 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                nextLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 13L10 8L5 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                pageClassName="page-item border border-white text-white bg-white"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(pageCount / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}
                            ></ReactPaginate>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ReferalDataExcel