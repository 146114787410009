import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import Forgot from './components/ForgotPassword/Forgot';
import NewPassword from './components/ForgotPassword/NewPassword';
import Success from './components/ForgotPassword/Success';
import Influencerdetail from './components/landing/Influencerdetail/Influencerdetail.js';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Groupsdetails from './components/landing/Groups/Groupdetails/Groupsdetails.js';
import Emailsent from './components/ForgotPassword/Emailsent.js';

function App() {
  return (
    <>
      <ToastContainer style={{ fontSize: '14px', zIndex: "9999999999999999" }} theme="dark" position="top-right" />
      <Router>
      <Routes>
        <Route path='/dashboard' element={<Landing />} />
        <Route path='/' element={<Login />} />
        <Route path='/forgot' element={<Forgot />} />
        <Route path='/reset-password' element={<NewPassword />} />
        <Route path='/success' element={<Success />} />
        <Route path='/influencerdetail' element={<Influencerdetail />} />
        {/* <Route path='/groupsdetails' element={<Groupsdetails />} /> */}
        <Route path='/emailsent' element={<Emailsent />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
