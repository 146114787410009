import React, { useEffect, useState } from "react";
import "./datatable.scss"
import { Link } from 'react-router-dom'
import tableup from '../../../../assets/tableup.svg'
import tabledown from '../../../../assets/tabledown.svg'
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";

const DataTable = ({ handleShow2, page, pageCount, setRoutes, handlePageChange, startIndex, endIndex, itemsPerPage, groupslist, setCampName, loader }) => {
  const [changetext, setchangetext] = useState(false)


  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date Created  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Campaign names  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Budget  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    CPA  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Total Influencers  <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Total Users <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Direct Users <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Users with 100% steps <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Average time per user<div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {groupslist?.groups?.map((data, index) => (
                <tr key={index}>
                  <td>
                    {data?.createdAt
                      ? moment(data?.createdAt).utc().utcOffset('+02:00').format('DD/MM/YYYY hh:mm')
                      : '0'}
                  </td>
                  <td>{data?.name || '0'}</td>
                  <td>${data?.totalBudget || '0'}</td>
                  <td>${isFinite(data?.totalInfluencers / data?.totalBudget) ? (data?.totalInfluencers / data?.totalBudget).toFixed(5) : '0.00'}</td>
                  <td>{data?.totalInfluencers || '0'}</td>
                  <td>{data?.totalUsers || '0'}</td>
                  <td>{data?.directRefferals || '0'}</td>
                  <td>{data?.totalCompletedUsers || '0'}</td>
                  {/* <td>{data?.averageUpdateTimeHours || '0'.toFixed(2)}</td> */}
                  <td>
                    {data?.averageCompleteTime !== undefined
                      ? `${(data?.averageCompleteTime / (1000 * 60)).toFixed(0)} minutes`
                      : '0 minutes'
                    }
                  </td>
                  <td>
                    <div className="dropdown">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="\assets\more-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            // to={`/dashboard?id=${data?._id}`}
                            to={`/dashboard?id=${data?._id}`}
                            onClick={() => {
                              setRoutes(true);
                            }}
                          >
                            <img
                              src="\assets\document-text.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            View Details
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => { setCampName({ name: data?.name, id: data?._id }); handleShow2() }}
                          >
                            Add Influencer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}

              {groupslist?.groups?.data?.length === 0 && (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="footer-content">
            <div className="left-f">
              {/* {loader || */}
              <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
              {/* } */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>

        <div className="mobiledesignss  d-none">
          {groupslist?.groups?.map((data, index) => (
            <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
              <div className="upper-body">
                <div className="inner-text">
                  <h6>Date Created</h6>
                  <p>  {data?.createdAt
                    ? moment(data?.createdAt).utc().utcOffset('+02:00').format('DD/MM/YYYY hh:mm')
                    : '0'}</p>
                </div>
                <div className="inner-text">
                  <h6>Campaign name</h6>
                  <p>{data?.name || '0'}</p>
                </div>
                <div className="inner-text">
                  <h6>Budget</h6>
                  <p>${data?.totalBudget || '0'}</p>
                </div>
                <div className="inner-text">
                  <h6>CPA</h6>
                  <p>${isFinite(data?.totalInfluencers / data?.totalBudget) ? (data?.totalInfluencers / data?.totalBudget).toFixed(5) : '0.00'}</p>
                </div>
                <div className="inner-text">
                  <h6>Total Influencers</h6>
                  <p>{data?.totalInfluencers || '0'}</p>
                </div>
                <div className="inner-text">
                  <h6>Total Users</h6>
                  <p>{data?.totalUsers || '0'}
                  </p>
                </div>
                <div className="inner-text">
                  <h6>Direct Users</h6>
                  <p>{data?.directRefferals || '0'}
                  </p>
                </div>

              </div>

              <div className="bottom-body">
                <Accordion>
                  <Accordion.Item className="bordernone" eventKey={data?._id}>
                    <Accordion.Header onClick={() =>
                      setchangetext(!changetext)
                    }>
                      {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-text">
                        <h6>Users with 100% steps</h6>
                        <p>{data?.totalCompletedUsers || '0'}</p>
                      </div>
                      <div className="inner-text">
                        <h6>Average time per user</h6>
                        <p>  {data?.averageCompleteTime !== undefined
                          ? `${(data?.averageCompleteTime / (1000 * 60)).toFixed(0)} minutes`
                          : '0 minutes'
                        }</p>
                      </div>
                      <Link
                        to={`/dashboard?id=${data?._id}`}
                        onClick={() => {
                          setRoutes(true);
                        }}
                      >
                        <button className="view-btn">View Details</button>
                      </Link>
                      <button onClick={handleShow2} className="view-btn mt-2">Add Influencer</button>
                      {/* <Link
                            className="dropdown-item"
                            to={`/dashboard?id=${data?._id}`}
                            onClick={() => {
                              setRoutes(true);
                            }}
                          >
                            <img
                              src="\assets\document-text.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            View Details
                          </Link> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          ))}
          <div className="footer-content">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
      </section>



    </>

  )
}

export default DataTable

