import { useState, useEffect } from 'react'
import "./navbar.scss";
import { api_url } from '../../../utils/Apiurl';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Groups from '../Groups/Groups';
import { toast } from 'react-toastify';
import AddInfluModal  from './AddInfluModal.js'
// import axios from 'axios'
import { id } from 'date-fns/locale';
import Multiselect from 'multiselect-react-dropdown';
import { Dropdown, Modal } from 'react-bootstrap'


const Navbar = ({
  sidebar,
  setSidebar,
  indexwait,
  setRend,
  setindexwait,
  setRoutes
}) => {
  const [showInfu, handleShowInfu] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputs, setInputs] = useState({
    name: "",
    groups: "",
  });
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [nameerror, setNameError] = useState("");
  const [grouperror, setGroupError] = useState("");
  const token = localStorage.getItem("miangInfu");
  const [groupsadd, setGroupsAdd] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [names, setNames] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ id: '', selectedname: '' });
  const [groupslist, setGroupsList] = useState([]);
  const [idArray, setIdArray] = useState([]);

  const AddGroups = async (e) => {
    e.preventDefault();
    setNameError("");
    setGroupError("");
    // Validation
    if (inputs.name === "") {
      setNameError("Name is required");
    }
    if (idArray.length >= 1) {
      console.log('lenght roti', idArray.length);
      // setGroupError("Group name is required");
      // return;
    } else {
      console.log('lenght roti 2222', idArray.length);
      // setGroupError("Group name is required");
    }
    try {
      const config = {
        method: 'post',
        url: `${api_url}/groups`,
        headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'application/json'
        },
        data: {
          name: name,
          influencerIds: idArray,
        }
      };
      const response = await axios(config);
      console.log(response);
      setInputs({
        name: "",
        groups: "",
      });
      setGroupsAdd(response?.data?.data);
      setRend(true);
      handleClose(false)
      toast.success("Campaign Created successfully");
      getGroups();
    } catch (error) {
      if (error) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred");
      }
      console.error('Error fetching project details:', error);
    }
  };

  const { name } = inputs;
  const handleChange = (e) => {
    console.log(e, "eeeeeeeeeee");
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    if (name === "name") {
      setNameError("")
    } else if (idArray === "groups") {
      setGroupError("")
    }
  };
  const getGroups = async () => {
    try {
      const response = await axios.get(`${api_url}/groups?offset=${`${page}0`}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGroupsList(response?.data?.data);
      setPageCount(response?.data?.data?.groupCount);
      console.log(response, "sssssssscecrf45");
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };

  useEffect(() => {
    getGroups()
  }, [page]);

  const handleLogout = () => {
    try {
      localStorage.removeItem("miangInfu");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Logout failed. Please try again.");
    }
  };

  const getAllNames = async (id, selectedname) => {
    try {
      const response = await axios.get(`${api_url}/influencers/getNames?&limit=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });;
      setNames(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };

  const handleSelect = (id, selectedname) => {
    setSelectedItem({ id, selectedname });
    getAllNames(id, selectedname);
  };

  useEffect(() => {
    getAllNames(selectedItem.id, selectedItem.selectedname);
  }, [selectedItem.id, selectedItem.selectedname]);
  const handleMultiselectRemove = (selectedList, removedItem) => {
    const selectedLeader = names.find((leader) => leader.name === removedItem);

    if (selectedLeader) {
      const selectedId = selectedLeader._id;
      const specialAccessExist = selectedItem?.id === selectedId;
      let res = idArray?.filter((item) => {
        return item !== selectedId
      })
      setIdArray(res)
      if (specialAccessExist) {
        // Handle your removal logic here
        // Filter out the item from your state or perform other actions
      }
    }
  };
  // console.log('idddddddddddddd', idArray);
  const handleMultiselectSelect = (selectedList, selectedName) => {
    const selectedLeader = names.find((leader) => leader.name === selectedName);

    if (selectedLeader) {
      const selectedId = selectedLeader._id;
      const specialAccessExist = selectedItem?.id === selectedId;
      setIdArray([...idArray, selectedId])
      console.log('idddddddddddddd', selectedId);
      if (!specialAccessExist) {
        // Handle your selection logic here
        // Add the item to your state or perform other actions
      }
    }
  };


  // const [indexwait, setindexwait] = useState(0);
  // const hitfunctionss = (asd) => {
  //   setindexwait(asd);
  //   localStorage.setItem("indexvalue", asd);
  // };
useEffect(() => {
  if(!token){
    localStorage.removeItem("miangInfu");
    navigate("/");
  }
}, [token])

  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-lg">
            <div className="twice-icons">
              <a onClick={() => setSidebar(!sidebar)} class="navbar-brand d-noneonmobile"><img src={sidebar ? "/assets/close-icon.svg" : "/assets/bar.svg"} alt="img" className="img-fluid" /></a>
              <a class="navbar-brand" onClick={() => {
                setRoutes(false);
                setindexwait(0);
              }}
              ><img src="\logo.svg" alt="img" className="img-fluid" /></a>
            </div>
            <button onClick={() => setSidebar(!sidebar)} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src={sidebar ? "/assets/close-icon.svg" : "/assets/bar.svg"} alt="img" className="img-fluid" />
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <button className="nav-btn" onClick={() => handleShowInfu(true)}>Add Influencer</button>
              <button className="nav-btn" onClick={handleShow}>Create Campaign</button>
              <button onClick={handleLogout} className="logout-btn"><img src="\assets\logout-icon.svg" alt="img" className="img-fluid" />Logout</button>
            </div>
          </nav>
        </div>
      </section>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show}
        onHide={() => { handleClose() }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="name"
                  name="name"
                  placeholder="Enter Campaign name"
                  value={name}
                  onChange={handleChange}
                  className={
                    "" +
                    (submitted && !name ? " is-invalid" : "")
                  }
                />
                <label>Name</label>
                {nameerror ? (
                  <p className="mt-2 text-danger email-error">{nameerror}</p>
                ) : null}
              </div>
            </div>
            <Multiselect
              className="multiselect-root"
              placeholder="Select Influencers"
              type="groups"
              name="groups"
              onChange={handleChange}
              value={idArray}
              // selectedValues={selectedItem?.selectedname ? [selectedItem.selectedname] : []}
              // className={
              //   "" +
              //   (submitted && !idArray ? " is-invalid" : "")
              // }
              isObject={false}
              onKeyPressFn={() => { }}
              onRemove={handleMultiselectRemove}
              onSearch={() => { }}
              onSelect={handleMultiselectSelect}
              customCloseIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              options={names?.map((item) => item?.name || "")}
            />
            {grouperror ? (
              <p className="mt-2 text-danger email-error">{grouperror}</p>
            ) : null}
            {/* <Dropdown onSelect={(id, event) => handleSelect(id, event.target.innerText)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedItem.selectedname && (
                  <span className="mainspan">
                    <p className="spanpara">{selectedItem.selectedname}</p>
                    <img src="\assets\influencers\close-circle.svg" alt="crossimg" className="crossimg" />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {names?.map((data, index) => (
                  <Dropdown.Item key={index} eventKey={data._id}>
                    {data.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <div className="twice-btn">
            <button
              onClick={handleClose}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              className="btn-add"
              onClick={(e) => AddGroups(e)}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <AddInfluModal showInfu={showInfu} handleShowInfu={handleShowInfu }/>
    </>
  );
};

export default Navbar;