import React, { useState, useEffect } from "react";
import "./datatable.scss"
import { Link } from 'react-router-dom'
import tableup from '../../../../assets/tableup.svg'
import tabledown from '../../../../assets/tabledown.svg'
import { Accordion } from "react-bootstrap";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

const DataTableUserExcel = ({ setRoutes, routes, allusers, excelId }) => {
    const [changetext, setchangetext] = useState(false)
   



    return (
        <>
            <section className="datatable d-none">
                <div className="table-responsive">
                    <table class="table" id={excelId}>
                        <thead>
                            <tr>
                                <th>
                                    <div className="parent-tag">
                                        Date/Time  <div className="filter-btn">

                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        IP/Country <div className="filter-btn">

                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Email <div className="filter-btn">

                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Unique link <div className="filter-btn">

                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Steps Progress <div className="filter-btn">

                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Total Invites <div className="filter-btn">

                                        </div>
                                    </div>
                                </th>

                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {allusers?.map((data, index) => {
                                const createdAtDate = new Date(data?.createdAt);
                                const formattedDate = createdAtDate.toLocaleDateString();
                                const formattedTime = moment(createdAtDate).format('hh:mm A');
                                return (
                                    <tr key={index}>
                                        <td>
                                            {formattedDate}
                                            {formattedTime}
                                        </td>
                                        <td>
                                            {data?.ip}
                                            {data?.location}
                                        </td>
                                        <td>{data?.influencer?.[0]?.email}</td>
                                        <td>
                                            {data?.influencerId?.referalCode || '0'}
                                        </td>
                                        <td>{data?.completionStatus || '0'} out of 7</td>
                                        <td>https://doptest.dop.org/id={data?.influencer?.[0]?.referalByCode || '0'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="footer-content d-none">
                        <div className="left-f">
                            <h6>SHOWING 1-10 OF 145</h6>
                        </div>
                        <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="mobiledesignss  d-none">
                    <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
                        <div className="upper-body">
                            <div className="inner-text">
                                <h6>Date</h6>
                                <p>30/06/2023</p>
                            </div>
                            <div className="inner-text">
                                <h6>Time</h6>
                                <p>08:47 UTC</p>
                            </div>
                            <div className="inner-text">
                                <h6>IP</h6>
                                <p>132.478.582</p>
                            </div>
                            <div className="inner-text">
                                <h6>Country</h6>
                                <p>Pakistan</p>
                            </div>
                        </div>
                        <div className="bottom-body">
                            <Accordion>
                                <Accordion.Item className="border-none">
                                    <Accordion.Header onClick={() =>
                                        setchangetext(!changetext)
                                    }>
                                        {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                                        <img
                                            src="\assets\arrow-down.svg"
                                            alt="img"
                                            className="img-fluid"
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="upper-body p-0">
                                            <div className="inner-text">
                                                <h6>Email</h6>
                                                <p>johndoe@gmail.com</p>
                                            </div>
                                            <div className="inner-text">
                                                <h6>Unique link</h6>
                                                <p>dop.org/8udsy <img src="\assets\copy.svg" alt="copy" className="copy" /></p>
                                            </div>
                                            <div className="inner-text">
                                                <h6>Steps Progress</h6>
                                                <p>6 out of 7</p>
                                            </div>
                                            <div className="inner-text">
                                                <h6>Total Invites</h6>
                                                <p> <img src="\assets\copy.svg" alt="copy" className="copy" /></p>
                                            </div>
                                        </div>
                                        <Link onClick={() => setRoutes(true)}>
                                            <button className="view-btn">View Details</button>
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="footer-content">
                        <div className="left-f">
                            {/* <h6>SHOWING 1-10 OF 145</h6> */}
                        </div>
                        <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DataTableUserExcel