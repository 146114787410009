import React, { useState, useEffect, useRef } from "react";
import "./datatable.scss"
import { Link, Routes } from 'react-router-dom'
import tableup from '../../../../assets/tableup.svg'
import tabledown from '../../../../assets/tabledown.svg'
import { Accordion } from "react-bootstrap";
import { api_url } from "../../../../utils/Apiurl";
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";
import moment from "moment";

const DataTableExcel = ({ influencer, page, pageCount, handlePageChange, itemsPerPage, endIndex, startIndex, setRoutes, routes, excelId }) => {
  const [changetext, setchangetext] = useState(false)

  const [copy, setCopy] = useState(null);
  const textCopiedFun2 = (index) => {
    setCopy(index);
    setTimeout(() => {
      setCopy(false);
    }, 500);
  };
  // console.log(data?.rewards?.createdAt,"ddfuuuuu88");
  console.log(influencer, "ddfu88");




  return (
    <>
      <section className="datatable d-none">
        <div className="table-responsive">
          <table class="table" id={excelId}>
            <thead>
              <tr>

                {/* <th>
                  <div className="parent-tag">
                    Campaign
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Link/Status
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Users
                  </div>
                </th> */}

                <th>
                  <div className="parent-tag">
                    User Id
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Created date & time (Connect Wallet)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    User type (Direct / Influencer / Referal)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Influencer URL (Main source of how he got to testnet)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Referral User URL (The url this user used for testnet)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Influencer Name Source (From the campaign. The main source of every user)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Campaign Name Source (The main campaign source of every user)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Which type of link bring this user to testnet (Direct, Influencer, Referral)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Connect Wallet (Yes / No)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 1
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 2
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 3
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 4
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 5
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 6
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 7
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Step 8
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    reward[0].completionStatus
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    reward[0].overallCompletionPercentage
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Average time to finish testnet
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Internal Wallet
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    External Wallet
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    e-mail Address
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    X (twitter user name)
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    reward[0].location
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    reward[0].ip
                  </div>
                </th>

                <th>

                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {influencer?.map((data, index) => (
                <tr>
                  <td>{data?._id}</td>
                  <td>
                    {data?.createdAt
                      ? moment(data?.createdAt).utc().utcOffset('+02:00').format('YYYY-MM-DD HH:mm:ss')
                      : 'None'}
                  </td>
                  <td>{data?.role}</td>
                  <td>https://doptest.dop.org/id={data?.referalCode}</td>
                  <td>https://doptest.dop.org/id={data?.referalCode}</td>
                  <td>{data?.name}</td>
                  <td>{data?.group?.[0]?.name}</td>
                  <td>
                    {data?.role === 'influencer'
                      ? 'Influencer'
                      : data?.role === 'referral'
                        ? 'Referral'
                        : 'Direct'}
                  </td>
                  <td>yes</td>
                  <td>{data?.task1}</td>
                  <td>{data?.task2}</td>
                  <td>{data?.task3}</td>
                  <td>{data?.task4}</td>
                  <td>{data?.task5}</td>
                  <td>{data?.task6}</td>
                  <td>{data?.task7}</td>
                  <td>{data?.task8}</td>
                  <td>{data?.rewards?.[index]?.completionStatus}</td>
                  <td>{data?.rewards?.[index]?.overallCompletionPercentage}</td>
                  <td>
                    {data?.rewards?.[index]?.createdAt && data?.rewards?.[index]?.updatedAt ? (
                      (moment(data?.rewards?.[index]?.updatedAt).diff(moment(data?.rewards?.[index]?.createdAt)) / (7 * 24 * 60 * 60 * 1000)).toFixed(1)
                    ) : 'None'}
                  </td>
                  <td>{data?.rewards?.[index]?.internalWalletAddress}</td>
                  <td>{data?.rewards?.[index]?.externalWalletAddress}</td>
                  <td>{data?.email}</td>
                  <td>not yet</td>
                  {/* <td></td> */}
                  {/* <td>
                    {data?.link}
                    {data?.isActive}
                  </td> */}
                  <td>{data?.rewards?.[index]?.location}</td>
                  <td>{data?.rewards?.[index]?.ip}</td>

                </tr>
              ))
              }
            </tbody>
          </table>
          <div className="footer-content">
            <div className="left-f">
              <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>

        <div className="mobiledesignss  d-none">
          {influencer?.map((data, index) => (
            <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
              <div className="upper-body">
                <div className="inner-text">
                  <h6>Name</h6>
                  <p>{data?.name}</p>
                </div>
                <div className="inner-text">
                  <h6>Email</h6>
                  <p>{data?.email}</p>
                </div>
                <div className="inner-text">
                  <h6>Link</h6>
                  <p>{data?.link}
                    <CopyToClipboard
                      text={data?.link}
                      onCopy={(e) => textCopiedFun2(index)}
                    >
                      <img className={copy === index && "copy-button"} src="\assets\copy.svg" alt="copy" />
                    </CopyToClipboard>
                  </p>
                </div>
                <div className="inner-text">
                  <h6>Status</h6>
                  <p className="yellow">{data?.isActive}</p>
                </div>
              </div>
              <div className="bottom-body">
                <Accordion>
                  <Accordion.Item eventKey={data?._id}>
                    <Accordion.Header onClick={() =>
                      setchangetext(!changetext)
                    }>
                      {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-textt">
                        <p>Total Users</p>
                        <h6>{data?.totalRewards}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 1</p>
                        <h6>{data?.task1}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 2</p>
                        <h6>{data?.task2}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 3</p>
                        <h6>{data?.task3}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 4</p>
                        <h6>{data?.task4}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 5</p>
                        <h6>{data?.task5}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 6</p>
                        <h6>{data?.task6}</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Step 7</p>
                        <h6>{data?.task7}</h6>
                      </div>
                      <Link
                        // to={`/influencerdetail?id=${data?._id}`}
                        onClick={() => {
                          setRoutes(!routes);
                        }}
                      >
                        <button className="view-btn">View Detailss</button>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          ))
          }
          <div className="footer-content">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M10 13L5 8L10 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L5 3"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                pageClassName="page-item border border-white text-white bg-white"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default DataTableExcel