import React, { useState } from "react";
import "./datatable.scss"
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";


const DataTableInfluencersExcel = ({ influencerbyid, page, pageCount, handlePageChange, itemsPerPage, endIndex, startIndex, excelId }) => {
    const [changetext, setchangetext] = useState(false)
    console.log(influencerbyid);


    return (
        <>
            <section className="datatable d-none">
                <div className="table-responsive">
                    <table class="table" id={excelId}>
                        <thead>
                            <tr>
                                <th>
                                    <div className="parent-tag">
                                        Date/Time
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        IP/Country
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 1
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 2
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 3
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 4
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 5
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 6
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 7
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Step 8
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {influencerbyid?.rewards?.map((reward, index) => {
                                const createdAtDate = new Date(reward?.createdAt);                             
                                const dateString = createdAtDate.toLocaleDateString();                             
                                return (
                                    <tr key={index}>
                                        <td>{dateString}{moment(reward?.createdAt)
                                            .utc()
                                            .utcOffset("+02:00")
                                            .format("hh:mm A")}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.ip}{influencerbyid?.rewards?.[index]?.location}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.follow_Us_On_Twitter?.isCompleted ? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.claim_Sepolia?.isCompleted ? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.claim_Dop?.isCompleted ? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.claim_Testnet_Assets?.isCompleted ? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.encrypt_Assets?.isCompleted ? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.send_Assets?.isCompleted? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.decrypt_Assets?.isCompleted ? "☑" : "☒"}</td>
                                        <td>{influencerbyid?.rewards?.[index]?.threeInvites?.isCompleted ? "☑" : "☒"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="footer-content">
                        <div className="left-f">
                            <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
                        </div>
                        <div className="right-f">
                            {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
                            <ReactPaginate
                                previousLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M10 13L5 8L10 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                nextLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 13L10 8L5 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                pageClassName="page-item border border-white text-white bg-white"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(pageCount / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}
                            ></ReactPaginate>
                        </div>
                    </div>
                </div>
                <div className="mobiledesignss  d-none">
                    {influencerbyid?.rewards?.map((reward, index) => {
                        const createdAtDate = new Date(reward.createdAt);
                        const dateString = createdAtDate.toLocaleDateString();
                        const timeString = createdAtDate.toLocaleTimeString();
                        return (
                            <div className="new-accordionmobile dsuygfdsyufgsdyuf ">
                                <div className="upper-body">
                                    <div className="inner-text">
                                        <h6>Date</h6>
                                        <p>{dateString}</p>
                                        {/* for new flow with date */}
                                        {/* <div className="status">
                        <h6 className="statushead">Completed</h6>
                        <p className="datepara">17/11/2023 03:06</p>
                      </div> */}
                                    </div>
                                    <div className="inner-text">
                                        <h6>Time</h6>
                                        <p>{timeString} </p>
                                    </div>
                                    <div className="inner-text">
                                        <h6>IP</h6>
                                        <p>{influencerbyid?.rewards?.[index]?.ip}</p>
                                    </div>
                                    <div className="inner-text">
                                        <h6>Country</h6>
                                        <p>{influencerbyid?.rewards?.[index]?.location}</p>
                                    </div>
                                </div>
                                <div className="bottom-body">
                                    <Accordion>
                                        <Accordion.Item eventKey={influencerbyid?._id}>
                                            <Accordion.Header onClick={() =>
                                                setchangetext(!changetext)
                                            }>
                                                {changetext ? <p>More</p> : <p>Less</p>} Info{" "}
                                                <img
                                                    src="\assets\arrow-down.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="inner-textt">
                                                    <p>Step 1</p>
                                                    <img src="/assets/tick-circle.svg" alt="tick-circle" />
                                                </div>
                                                <div className="inner-textt">
                                                    <p>Step 2</p>
                                                    <img src="/assets/tick-circle.svg" alt="tick-circle" />
                                                </div>
                                                <div className="inner-textt">
                                                    <p>Step 3</p>
                                                    <img src="/assets/tick-circle.svg" alt="tick-circle" />
                                                </div>
                                                <div className="inner-textt">
                                                    <p>Step 4</p>
                                                    <img src="/assets/tick-circle.svg" alt="tick-circle" />
                                                </div>
                                                <div className="inner-textt">
                                                    <p>Step 5</p>
                                                    <img src="/assets/close-circle.svg" alt="close-circle" />
                                                </div>
                                                <div className="inner-textt">
                                                    <p>Step 6</p>
                                                    <img src="/assets/close-circle.svg" alt="close-circle" />
                                                </div>
                                                <div className="inner-textt">
                                                    <p>Step 7</p>
                                                    <img src="/assets/close-circle.svg" alt="close-circle" />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                    <div className="footer-content">
                        <div className="left-f">
                            {/* <h6>SHOWING 1-10 OF 145</h6> */}
                        </div>
                        <div className="right-f">
                            {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
                            <ReactPaginate
                                previousLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M10 13L5 8L10 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                nextLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 13L10 8L5 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                pageClassName="page-item border border-white text-white bg-white"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(pageCount / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}
                            ></ReactPaginate>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DataTableInfluencersExcel