import React, { useState, useEffect } from 'react'
import { api_url } from '../../../utils/Apiurl'
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from 'react-toastify';
import { Accordion, Dropdown, Modal} from 'react-bootstrap'
const AddInfluModal = (
    { showInfu, handleShowInfu, influencerDetail,getInfluencerdetails }
) => {
    const [inputs, setInputs] = useState({
        email: influencerDetail?.email,
        name: influencerDetail?.name,
        budget: influencerDetail?.budget,
        isInternal: influencerDetail?.isInternal, 
    });
    const [nameerror, setNameError] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [budgeterror, setBudgetError] = useState("");
    const [influenceradd, setInfluencerAdd] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const token = localStorage.getItem("miangInfu");
    const [submitted, setSubmitted] = useState(false);
    const [groupstoadd, setGroupsToAdd] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ id: '', selectedname: '' });
    const handleClose = () => handleShowInfu(false);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [topcampaignslist, setTopCampaingslist] = useState([]);
    const [copiedItemId, setCopiedItemId] = useState(null);

    const AddInfluencers = async (e) => {
        e.preventDefault();
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        // reset error states
        setNameError("");
        setEmailError("");
        setBudgetError("");
        // validation
        if (inputs.name === "") {
            setNameError("Name is required");
            return
        }
        if (inputs.email.trim() === "") {
            setEmailError("Email is required");
            return
        } else if (!emailPattern.test(inputs.email)) {
            setEmailError("Invalid email format");
            return
        }
        if (inputs.budget === "") {
            setBudgetError("Budget is required");
            return
        }
        try {
            const config = {
                method: "patch",
                url: `${api_url}/influencers/${influencerDetail?._id}`,
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            };
            let data;
            if (selectedItem && selectedItem.id) {
                data = {
                    name: inputs.name,
                    email: inputs.email,
                    budget: parseFloat(inputs.budget),
                    groupId: selectedItem.id,
                    isInternal
                };
            } else {
                data = {
                    name: inputs.name,
                    email: inputs.email,
                    budget: parseFloat(inputs.budget),
                    isInternal
                };
            }
            config.data = data;
            const response = await axios(config);
            console.log(response.data);
            getInfluencerdetails()
            setInfluencerAdd(response?.data?.data);
            setApiSuccess(true);
            // handleClose();
            handleShow1();
            handleShowInfu(false)
            toast.success("Changes saved successfully");
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message);
            } else {
                toast.error("An error occurred");
            }
            console.error('Error fetching project details:', error);
        }
    };


    const getGroupstoAdd = async (id, selectedname) => {
        try {
            const response = await axios.get(`${api_url}/groups/getNames?&limit=100`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            setGroupsToAdd(response?.data?.data);

        } catch (err) {
            console.error('Error fetching influencers:', err);
        }
    };


    const { email, name, budget, isInternal } = inputs;
    const handleChange = (e) => {
        const { name, value,checked } = e.target;
        if (name ==='isInternal'){
            setInputs((inputs) => ({ ...inputs, [name]: checked }));
        }else{
            setInputs((inputs) => ({ ...inputs, [name]: value }));
        }
        if (name === "email") {
            setEmailError("")
        } else if (name === "name") {
            setNameError("")
        } else if (name === "budget") {
            setBudgetError("")
        }
    };

    // const getTopCampaings = async () => {
    //     try {
    //         const response = await axios.get(`${api_url}/groups/topCampaings`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         setTopCampaingslist(response?.data?.data?.data);
    //     } catch (err) {
    //         console.error('Error fetching influencers:', err);
    //     }
    // };
    useEffect(() => {
        // getTopCampaings()
        getGroupstoAdd()
    }, [])



    const handleSelect = (id, selectedname) => {
        setSelectedItem({ id, selectedname });
        // setGroupError("")
    };

    const textCopiedFun2 = (itemId) => {
        console.log(itemId, 'itemId');
        setCopiedItemId(itemId);
        setTimeout(() => {
            setCopiedItemId(null);
        }, 500);
    };
useEffect(() => {
   setInputs({
        email: influencerDetail?.email,
        name: influencerDetail?.name,
        budget: influencerDetail?.budget,
       isInternal: influencerDetail?.isInternal,
    });
}, [influencerDetail])

    return (
        <>
            <Modal
                className="add-mega-modal global-modal-scss"
                show={showInfu}
                onHide={() => { handleClose() }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-mega-div">
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="name"
                                    name="name"
                                    placeholder="Enter influencer’s Name"
                                    value={name}
                                    onChange={handleChange}
                                    className={
                                        "" +
                                        (submitted && !name ? " is-invalid" : "")
                                    }
                                />
                                {nameerror ? (
                                    <p className="mt-2 text-danger email-error">{nameerror}</p>
                                ) : null}
                                <label>Name</label>
                            </div>
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter influencer’s Email address"
                                    value={email}
                                    onChange={handleChange}
                                    className={
                                        "" +
                                        (submitted && !email ? " is-invalid" : "")
                                    }
                                />
                                {emailerror ? (
                                    <p className="mt-2 text-danger email-error">{emailerror}</p>
                                ) : null}
                                <label>Email</label>
                            </div>
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="number"
                                    name="budget"
                                    value={budget}
                                    onChange={handleChange}
                                    placeholder="Enter influencer’s budget"
                                    className={
                                        "" +
                                        (submitted && !budget ? " is-invalid" : "")
                                    }
                                />
                                {budgeterror ? (
                                    <p className="mt-2 text-danger email-error">{budgeterror}</p>
                                ) : null}
                                <label>Budget</label>
                                <img src="\assets\dollar.svg" alt="dollarimg" className="dollarimg" />
                            </div>
                        </div>

                        {/* <Multiselect
              className="multiselect-root"
              placeholder="Select Campaign"
              // selectedValues={selectedItem?.selectedname ? [selectedItem.selectedname] : []}
              isObject={false}
              onKeyPressFn={() => { }}
              onRemove={handleMultiselectRemove}
              onSearch={() => { }}
              onSelect={handleMultiselectSelect}
              customCloseIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              options={groupstoadd?.map((item) => item?.name)}
            />  */}
                        <Dropdown>
                            <Dropdown.Toggle>
                                {influencerDetail?.groupId?.name || 'Select Campaign'}
                            </Dropdown.Toggle>
                            {/* <Dropdown.Menu>
                                {groupstoadd?.map((data, index) => (
                                    <Dropdown.Item
                                        onClick={() => handleSelect(data._id, data.name)}
                                        key={index}
                                        eventKey={data._id}
                                    >
                                        {data?.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu> */}
                            {/* {grouperror ? (
                <p className="text-danger email-error">{grouperror}</p>
              ) : null} */}
                        </Dropdown>
                    </div>
                    <div className="checkboxmain">
                        <div class="content">
                            <label class="checkBox">
                                <input value={isInternal} checked={isInternal}
                                    onChange={handleChange} type="checkbox" name='isInternal' />
                                <div class="transition"></div>
                            </label>
                        </div>
                        <p className="checkboxpara">Internal marketing account{console.log('isInternal', isInternal)}</p>
                    </div>
                    <div className="twice-btn">
                        <button
                            onClick={handleClose}
                            className="btn-cancel"
                        >
                            Back
                        </button>
                        <button
                            onClick={(e) => {
                                AddInfluencers(e);
                            }}
                            className="btn-add"
                        >
                            Add
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {
                apiSuccess && (
                    <Modal
                        className="add-mega-modal global-modal-scss"
                        show={show1}
                        onHide={handleClose1}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="editpara">Changes saved successfully</p>
                            <button onClick={() => {
                                handleClose1();
                                handleClose();
                            }} className="okaybtn">Okay</button>
                           
                        </Modal.Body>
                    </Modal>
                )
            }
        </>
    )
}

export default AddInfluModal