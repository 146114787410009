import React from 'react'
import "./forgot.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'


const Success = () => {
  return (
    <>
    <div className="forgotpassword-wrapper">
      <section className="forgot-password">
        <div className="custom-container">
            <div className="parent-card">
                <div className="upper-logo">
                    <img src="\assets\forgot\forgot-logo.svg" alt="img" className='img-fluid' />
                </div>
                <div className="bottom-content newcontentt">
                    <img src="\assets\forgot\happyface.svg" alt="img" className='img-fluid' />
                    <h6 className='text-center'>Password changed successfully</h6>
                   <Link className='w-100' to="/"> <button className='btn-send'>Login</button></Link>
                </div>
            </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default Success
